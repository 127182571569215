.password-input-wrapper {
  position: relative;
}

.password-input-wrapper input {
  padding-right: 40px;
}

.password-toggle,.password-toggle-reset {
  position: absolute;
  right: 10px;
  top: -73.5%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999;
}
.password-toggle-reset{
  top: 72% !important;
}

.password-toggle:hover,.password-toggle-reset:hover {
  color: #333;
}
// hide reveal btn in edge and internet explorer
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}
// hide reveal btn in safari,chrome,firefox
input[type=password]::-webkit-contacts-auto-fill-button,
input[type=password]::-webkit-credentials-auto-fill-button {
   display: none !important;
}