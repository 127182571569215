@import "./variables.module";

.customer-dashboard {
  padding: 50px 0;
  background: #fff;
  min-height: 100%;
  .customer-dashboard-header {
    color: $primaryColor;
    margin-bottom: 40px;
    padding-top: 15px;
    // align-items: center;

    img.customer-img {
      max-height: 200px;
      display: block;
      margin: auto;
      margin-bottom: 20px;
    }

    h5.customer-name {
      font-size: 20px;
      font-weight: 600;
    }

    p.customer-credit {
      background: lighten($goldenHover, 20%);
      display: inline;
      border-radius: 50rem;
      padding: 5px 15px;
      font-weight: 600;
    }
  }

  .jobs-payment-div {
    border: 1px solid $borderColor;
    padding: 10px;
    margin-top: 30px;

    h3 {
      color: $primaryColor;
      background-color: $accordionBackground;
      padding: 8px 13px;
      margin: -11px;
      margin-bottom: 20px;
      font-size: 18px;
    }

    .jobs-payment-summary-table {
      border: 1px solid $borderColor;
      text-align: center;
      color: $primaryColor;
      margin-bottom: 10px;

      th,
      td {
        border: 1px solid $borderColor;
      }

      th {
        width: 25%;
        padding: 10px 10px;
      }

      td {
        padding: 10px;
        font-weight: 600;
      }
    }

    .jobs-payment-details-row {
      text-align: center;
      color: $primaryColor;
      padding: 0 10px;
      padding-bottom: 20px;
      overflow-x: scroll;

      @media (min-width: 992px) {
        padding-bottom: 0px;
        overflow-x: unset;
      }

      .row {
        min-width: 600px;
        position: relative;
        border-radius: 50rem;
        align-items: center;
        border-radius: 50rem;
        padding: 5px 0px;
        margin-top: 10px;

        &:nth-child(odd) {
          background-color: $accordionBackground;
          border: 1px solid rgb(223, 223, 223);
        }

        &:nth-child(even) {
          border: 1px solid lighten($primaryColor, 50%);
        }

        &.due {
          border: 1.5px solid lighten($deleteColor, 30%);
        }

        img {
          height: 40px;
          width: 40px;
          object-fit: contain;
          margin-left: 10px;
          display: flex;
          border-radius: 50rem;
          border: 1px solid $accordionBackground;
          background: #fff;
        }

        p {
          line-height: 1;
          font-weight: 600;
          margin: 0;
          font-size: 100%;
          text-transform: capitalize;
        }

        span {
          font-size: 80%;
          display: block;
          // font-weight: 600;
          line-height: 1;
          margin-bottom: 5px;
        }

        a {
          text-transform: uppercase;
          background-color: transparent;
          border: 1px solid $primaryColor;
          color: $textColor;
          font-size: 70%;
          &:hover,
          &:focus {
            background-color: transparent;
            color: $textColor;
            font-weight: bold;
          }
        }

        .col {
          padding: 0 5px;
        }
      }
    }
  }
  .view-more-btn {
    margin-top: 20px;
    // float: right;
    display: block;
    margin-left: auto;
    text-transform: uppercase;
    height: 40px;
    min-width: 176px;
    max-width: 200px;
    background-color: $whiteColor;
    border: 1px solid $primaryColor;
    color: $textColor !important;
    padding-top: 9px;
    &:hover,
    &:focus {
      background-color: $whiteColor;
      color: $textColor;
      font-weight: bold;
    }
  }
}

.cborder {
  border: 1px solid darken($borderColor, 10%);
}

.enquiries-container {
  padding: 50px 0px;
  background: #fff;
  min-height: 100%;

  .enquiries-container-titlebar {
    color: #fff;
    background-color: $headerBgColor;
    position: relative;
    font-family: "Raleway";
    font-size: 15px;
    font-weight: bold;
    padding-top: 0;
    cursor: pointer;
    margin: 0;

    .col-12 {
      display: flex;
      align-items: center;
    }

    .enquiries-num {
      margin-left: auto;
      padding: 10px 15px;
      font-size: 20px;
      font-family: Arial;
      background: $accordionBackground;
      color: $primaryColor;
    }
  }

  .enquiry-titlebar {
    color: $primaryColor;
    font-weight: 600;
    text-align: center;
    padding: 15px 0;
    padding-bottom: 0;
    margin: 0;
    line-height: 1.3;
    align-items: center;

    span {
      word-break: break-word;
      white-space: pre-line;
      overflow-wrap: break-word;
    }
  }

  .enquiries-box {
    margin: 15px;
    border: 1px solid $borderColor;

    .commodityTitlebar:not(.default) {
      background: $tableHeaderColor;
      text-align: center;
      color: #fff;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      margin: 0;
      font-size: 13px;
      font-weight: bold;
      font-family: Arial;
      line-height: 1.3;
      cursor: pointer;
      text-transform: capitalize;

      word-break: break-word;
      white-space: pre-line;
      overflow-wrap: break-word;
      img {
        background: #fff;
        border-radius: 100%;
        display: block;
        margin: 0 10px 0 15px;
      }

      .titleBarPrice {
        // padding: 17px 10px;
        padding: 2px 5px;
        height: 60px;
        text-align: center;
        margin-left: auto;
        background: $headerBgColor;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.3;
        min-width: 80px;
        max-width: 80px;
        font-size: 11px;
        @media(min-width:1024px){
          min-width: 80px;
        }
        @media(max-width:1024px){
          min-width: 65px;
        }
      }
      .cancelledRateEnquiry {
        // padding: 17px 10px;
        padding: 2px 5px;
        height: 40px;
        text-align: center;
        margin-left: auto;
        background: $deleteColor;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.3;
        min-width: 100px;
        max-width: 120px;
      }

      svg {
        margin-left: 10px;
        margin-right: 10px;
      }

      &.light {
        background: $accordionBackground;
        color: $primaryColor;
      }
    }

    .commodityTitlebar.default {
      color: $tableHeaderColor;
      background-color: $tableAlternateBgColor;
      padding: 10px 10px;
    }

    .ratingTitlebar {
      margin: 10px;
      margin-bottom: 0;
      text-align: center;

      .col-1 {
        text-align: left;
      }
    }



    .fileList {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .div-action {
        transform: scale(0.8);
        // margin: -3px 0;
        padding-right: 30px;
      }
    }

    .table:not(.default) {
      // width: calc(100% - 28px);
      margin: 0 auto 15px auto;

      thead tr th,
      tbody tr td {
        // background: #fff !important;
        border: 1px solid darken($borderColor, 10%) !important;
        padding: 5px 5px;
        width: 200px;
        line-height: 1.2;
        vertical-align: middle;
        color: $primaryColor;
        position: relative;
        img {
          background: $headerBgColor;
          border-radius: 100%;
          margin-right: 5px;
          // margin-top: 5px;
          // margin-bottom: 5px;
        }
      }

      thead {
        background: $accordionBackground !important;

        tr th {
          padding: 10px 5px;
        }

        &.text-white {
          tr th {
            background: $headerBgColor;
            color: #fff;
          }
        }
      }

      tr.total {
        background: $accordionBackground !important;
        td {
          // padding: 15px 0px;
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
        }
      }

      .sub-price-title {
        display: flex;
        align-items: center;
        // width: calc(100% + 8px);
        margin-left: -4px;
        margin-right: -4px;
        margin-top: 10px;
        margin-bottom: -10px;
        background: #fff;
        border-top: 1px solid darken($borderColor, 10%);
        min-width: 200px;

        div {
          padding: 5px 5px 3px 5px;
          width: 50%;
          font-size: 90%;
          text-transform: uppercase;

          &:first-child {
            width: 40%;
            margin-right: -0.5px;
            border-right: 1px solid darken($borderColor, 10%);
          }
          &:last-child {
            width: 60%;
            margin-left: -0.5px;
            border-left: 1px solid darken($borderColor, 10%);
          }
        }
        // div + div {
        //   border-left: 1px solid darken($borderColor, 10%);
        // }
      }
      .sub-price-amt {
        display: flex;
        align-items: center;
        // width: calc(100% + 10px);
        // margin: 0 -5px;
        // margin-bottom: -5px;
        // margin-top: -5px;
        background: #fff;
        min-width: 200px;
        // position: absolute;
        // top: 0;
        // height: 100%;
        // // bottom: 0;
        // right: 0;
        // left: 0;
        div {
          height: 100%;
          padding: 8px 5px 5px 5px;
          width: 50%;
          &:first-child {
            width: 40%;
            margin-right: -0.5px;
            border-right: 1px solid darken($borderColor, 10%);
          }
          &:last-child {
            width: 60%;
            margin-left: -0.5px;
            border-left: 1px solid darken($borderColor, 10%);
          }
        }
        // div + div {
        //    border-left: 1px solid darken($borderColor, 10%);
        // }
      }

      .sub-price-total {
        display: flex;
        align-items: center;
        // width: calc(100% + 10px);
        // margin: 0 -5px;
        // margin-bottom: -10px;
        // margin-top: -10px;
        min-width: 200px;
        // position: absolute;
        // top: 0;
        // // bottom: 0;
        // height: 100%;
        // right: 0;
        // left: 0;
        div {
          height: 100%;
          padding: 15px 5px 15px 5px;
          width: 50%;

          &:first-child {
            width: 40%;
            margin-right: -0.5px;
            border-right: 1px solid darken($borderColor, 10%);
          }
          &:last-child {
            width: 60%;
            margin-left: -0.5px;
            border-left: 1px solid darken($borderColor, 10%);
          }
        }
        // div + div {
        //   border-left: 1px solid darken($borderColor, 10%);
        // }
      }
    }

    .card {
      background: rgba($borderColor, 0.2);
    }

    .table {
      background: #fff;
    }
  }

  .invoice-view-btn {
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    float: right;
    padding-left: 25px;
  }
}

.enquiries-container-pricing {
  padding: 10px 0px;
  background: #fff;
  min-height: 100%;

  .enquiries-container-titlebar {
    color: #fff;
    background-color: $headerBgColor;
    position: relative;
    font-family: "Raleway";
    font-size: 15px;
    font-weight: bold;
    padding-top: 0;
    cursor: pointer;
    margin: 0;

    .col-12 {
      display: flex;
      align-items: center;
    }

    .enquiries-num {
      margin-left: auto;
      padding: 10px 15px;
      font-size: 20px;
      font-family: Arial;
      background: $accordionBackground;
      color: $primaryColor;
    }
  }

  .enquiry-titlebar {
    color: $primaryColor;
    font-weight: 600;
    text-align: center;
    padding: 15px 0;
    padding-bottom: 0;
    margin: 0;
    line-height: 1.3;
    align-items: center;

    span {
      word-break: break-word;
      white-space: pre-line;
      overflow-wrap: break-word;
    }
  }

  .enquiries-box {
    margin: 15px;
    border: 1px solid $borderColor;

    .commodityTitlebar:not(.default) {
      background: $tableHeaderColor;
      text-align: center;
      color: #fff;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      margin: 0;
      font-size: 13px;
      font-weight: bold;
      font-family: Arial;
      line-height: 1.3;
      cursor: pointer;
      text-transform: capitalize;

      word-break: break-word;
      white-space: pre-line;
      overflow-wrap: break-word;
      img {
        background: #fff;
        border-radius: 100%;
        display: block;
        margin: 0 10px 0 15px;
      }

      .titleBarPrice {
        // padding: 17px 10px;
        padding: 2px 5px;
        height: 60px;
        text-align: center;
        margin-left: auto;
        background: $headerBgColor;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.3;
        min-width: 80px;
        max-width: 80px;
        font-size: 11px;
        @media(min-width:1024px){
          min-width: 80px;
        }
        @media(max-width:1024px){
          min-width: 65px;
        }
      }
      .cancelledRateEnquiry {
        // padding: 17px 10px;
        padding: 2px 5px;
        height: 40px;
        text-align: center;
        margin-left: auto;
        background: $deleteColor;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.3;
        min-width: 100px;
        max-width: 120px;
      }

      svg {
        margin-left: 10px;
        margin-right: 10px;
      }

      &.light {
        background: $accordionBackground;
        color: $primaryColor;
      }
    }

    .commodityTitlebar.default {
      color: $tableHeaderColor;
      background-color: $tableAlternateBgColor;
      padding: 10px 10px;
    }

    .ratingTitlebar {
      margin: 10px;
      margin-bottom: 0;
      text-align: center;

      .col-1 {
        text-align: left;
      }
    }



    .fileList {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .div-action {
        transform: scale(0.8);
        // margin: -3px 0;
        padding-right: 30px;
      }
    }

    .table:not(.default) {
      // width: calc(100% - 28px);
      margin: 0 auto 15px auto;

      thead tr th,
      tbody tr td {
        // background: #fff !important;
        border: 1px solid darken($borderColor, 10%) !important;
        padding: 5px 5px;
        width: 200px;
        line-height: 1.2;
        vertical-align: middle;
        color: $primaryColor;
        position: relative;
        img {
          background: $headerBgColor;
          border-radius: 100%;
          margin-right: 5px;
          // margin-top: 5px;
          // margin-bottom: 5px;
        }
      }

      thead {
        background: $accordionBackground !important;

        tr th {
          padding: 10px 5px;
        }

        &.text-white {
          tr th {
            background: $headerBgColor;
            color: #fff;
          }
        }
      }

      tr.total {
        background: $accordionBackground !important;
        td {
          // padding: 15px 0px;
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
        }
      }

      .sub-price-title {
        display: flex;
        align-items: center;
        // width: calc(100% + 8px);
        margin-left: -4px;
        margin-right: -4px;
        margin-top: 10px;
        margin-bottom: -10px;
        background: #fff;
        border-top: 1px solid darken($borderColor, 10%);
        min-width: 200px;

        div {
          padding: 5px 5px 3px 5px;
          width: 50%;
          font-size: 90%;
          text-transform: uppercase;

          &:first-child {
            width: 40%;
            margin-right: -0.5px;
            border-right: 1px solid darken($borderColor, 10%);
          }
          &:last-child {
            width: 60%;
            margin-left: -0.5px;
            border-left: 1px solid darken($borderColor, 10%);
          }
        }
        // div + div {
        //   border-left: 1px solid darken($borderColor, 10%);
        // }
      }
      .sub-price-amt {
        display: flex;
        align-items: center;
        // width: calc(100% + 10px);
        // margin: 0 -5px;
        // margin-bottom: -5px;
        // margin-top: -5px;
        background: #fff;
        min-width: 200px;
        // position: absolute;
        // top: 0;
        // height: 100%;
        // // bottom: 0;
        // right: 0;
        // left: 0;
        div {
          height: 100%;
          padding: 8px 5px 5px 5px;
          width: 50%;
          &:first-child {
            width: 40%;
            margin-right: -0.5px;
            border-right: 1px solid darken($borderColor, 10%);
          }
          &:last-child {
            width: 60%;
            margin-left: -0.5px;
            border-left: 1px solid darken($borderColor, 10%);
          }
        }
        // div + div {
        //    border-left: 1px solid darken($borderColor, 10%);
        // }
      }

      .sub-price-total {
        display: flex;
        align-items: center;
        // width: calc(100% + 10px);
        // margin: 0 -5px;
        // margin-bottom: -10px;
        // margin-top: -10px;
        min-width: 200px;
        // position: absolute;
        // top: 0;
        // // bottom: 0;
        // height: 100%;
        // right: 0;
        // left: 0;
        div {
          height: 100%;
          padding: 15px 5px 15px 5px;
          width: 50%;

          &:first-child {
            width: 40%;
            margin-right: -0.5px;
            border-right: 1px solid darken($borderColor, 10%);
          }
          &:last-child {
            width: 60%;
            margin-left: -0.5px;
            border-left: 1px solid darken($borderColor, 10%);
          }
        }
        // div + div {
        //   border-left: 1px solid darken($borderColor, 10%);
        // }
      }
    }

    .card {
      background: rgba($borderColor, 0.2);
    }

    .table {
      background: #fff;
    }
  }

  .invoice-view-btn {
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    float: right;
    padding-left: 25px;
  }
}

.shipperConsigneeDetails {
  color: $primaryColor;
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  font-family: Arial;
  padding: 15px 0px;
  // justify-content: center;

  .cborder {
    height: 100%;
    padding-bottom: 7px;
  }

  h6 {
    background: $accordionBackground;
    padding: 8px 10px;
    // margin-bottom: 3px;
    display: block;
  }

  p {
    margin: 0;
    padding: 0 10px;
    line-height: 1.5;
    display: flex;

    strong {
      min-width: 40px;
    }

    div {
      padding-left: 10px;
      white-space: pre-line;
    }
  }
}

.pbg {
  th,
  td {
    padding: 4px 5px !important;
    border: 1px solid darken($borderColor, 10%);
  }

  .div-action {
    transform: scale(0.8);
    padding-right: 30px;
    // margin: -3px 0;
  }
}

.operationStatus {
  color: $primaryColor;
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  font-family: Arial;
  padding: 15px 0px;
  // justify-content: center;

  .col-lg-6 {
    .cborder {
      height: 100%;
      padding-bottom: 8px;
    }
  }

  .paymentReleasedToVendorDetails {
    // padding: 0 0 10px 70px;
    margin: 0 10px 0 10px;
  }

  h6 {
    background: $tableHeaderColor;
    color: #fff;
    padding: 12px;
    margin-bottom: 10px;
    display: block;
  }

  p {
    margin: 5px 0;
    padding: 0 10px;
    line-height: 1.5;
    display: flex;
    align-items: center;
    // flex-wrap: wrap;
    .spinner-border-sm {
      padding-left: 0px !important;
    }

    strong {
      min-width: 120px;
    }

    div {
      padding-left: 10px;

      .input-group {
        border-radius: 50rem;
        overflow: hidden;
        background: #fff;
        cursor: pointer;

        .file-box-kyc {
          padding-top: 5px;
          width: 150px;
        }

        img {
          padding: 0 2px 0px 0;
          background-color: $accordionBackground;
        }
      }

      #download-div {
        transform: scale(0.8);
        margin: -3px 0;
      }
    }
  }

  .statusHistory {
    overflow-x: auto;

    p {
      strong {
        min-width: 70px;
      }
      margin: 1px 0;

      .div-action {
        transform: scale(0.8);
        padding-right: 30px;
        // margin: -3px 0;
      }
    }
  }
}

.operationStatus-steps {
  position: relative;
  margin: 10px 0 25px 0;

  .steps {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .stepsBox {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      &:before {
        content: "";
        background-color: $tableAlternateBgColor;
        right: 0;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 10px;
        border: 20px;
        width: 100%;
        padding-right: 10px;
        position: absolute;
      }

      &:first-child {
        width: 50px;
      }

      .icon {
        text-align: center;
        background-color: $tableAlternateBgColor;
        color: #fff;
        display: inline-block;
        position: relative;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        font-size: 28px;
        z-index: 5;
        line-height: 55px;

        .fa-check {
          font-size: 35px;
          margin-left: -2px;
          margin-top: 3px;
          margin-bottom: -3px;
        }

        .fa-ban {
          font-size: 30px;
          margin-left: 0.5px;
          margin-bottom: 1px;
        }

        .fa-times {
          font-size: 42px;
          margin-top: 4px;
          margin-bottom: -4px;
        }

        .fa-hourglass-half {
          font-size: 25px;
          margin-top: -5px;
        }

        h6 {
          margin-left: -20px;
          font-size: 12px;
          width: 90px;
          color: $disabledColor;
          text-align: center;
          line-height: 1;
        }
      }
    }

    .stepsBox.completed {
      &:before {
        background-color: $primaryColor !important;
      }

      .icon {
        background-color: $primaryColor !important;
        color: #fff;

        h6 {
          color: $primaryColor !important;
        }
      }
    }

    // .stepsBox.completed + .stepsBox:not(.completed) {
    //   &:before {
    //     background-color: $tableAlternateBgColor !important;
    //   }

    //   .icon {
    //     background-color: $addColor !important;
    //     color: #fff;

    //     h6 {
    //       color: $addColor !important;
    //     }
    //   }
    // }

    &.canceled .stepsBox {
      &:before {
        background-color: $deleteColor;
      }
      &.ongoing::before {
        background-color: $deleteColor !important;
      }

      .icon {
        background-color: $deleteColor;
        color: #fff;

        h6 {
          color: $deleteColor;
        }
      }
    }
  }
}

.photo-viewer-container {
  min-height: 80vh;
  img {
    height: 100% !important;
    width: 100% !important;
    object-fit: contain;
  }
}

.pg-viewer {
  max-height: 80vh;
}

.charges-row {
  background: $disabledBgColor;
  border: 1px solid darken($borderColor, 10%);
  .charges {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    color: $primaryColor;
    font-size: 90%;
    margin: 5px 0;
    padding: 5px;
    strong {
      padding-right: 5px;
    }
  }
}

.pricingRate{
  gap: 1rem;
  p {
    color: $primaryColor;
    text-wrap: nowrap;
    }
}
.primaryText{
  color: $primaryColor;
}
.ovalBg{
  background: $accordionBackground;
  border-radius: 50rem;
  padding: 5px 15px;
  line-height: 1.2;
  margin: 0;
}
.followUp{
  display: flex;
  // flex-wrap: wrap;
  // align-items: center;
  color: $primaryColor;
  padding: 5px 10px 5px 5px;
  strong {
    padding-right: 5px;
    padding-top: 3px;
  }

  p {
    border-radius: 50rem;
    padding: 2px 5px;
    line-height: 1.2;
    margin: 0;
    white-space: break-spaces;
    // max-width: 600px;
  }

  .div-action {
    padding-right: 30px;
    transform: scale(0.8);
  }

}
.followUp{
  p{
    word-break: break-word;
  }
}

.query-ports {
  display: flex;
  // flex-wrap: wrap;
  // align-items: center;
  color: $primaryColor;
  padding: 5px 10px 5px 5px;
  strong {
    padding-right: 5px;
    padding-top: 3px;
  }
  span{
    white-space: nowrap;
    padding-right: 5px;
    padding-top: 3px;
    font-weight: bolder;
  }

  p {
    background: $accordionBackground;
    border-radius: 50rem;
    padding: 5px 15px;
    line-height: 1.2;
    margin: 0;
    white-space: break-spaces;
  }

  .div-action {
    padding-right: 30px;
    transform: scale(0.8);
  }
}

.query-ports-pricing {
  display: flex;
  // flex-wrap: wrap;
  // align-items: center;
  color: $primaryColor;
  padding: 5px 10px 5px 5px;
  strong {
    padding-right: 5px;
    padding-top: 3px;
  }
  span{
    white-space: nowrap;
    padding-right: 5px;
    padding-top: 3px;
    font-weight: bolder;
  }

  p {
    // background: $accordionBackground;
    border-radius: 50rem;
    padding: 4px 0px;
    line-height: 1.2;
    margin: 0;
    // white-space: break-spaces;
    text-align: left;
  }

  .div-action {
    padding-right: 30px;
    transform: scale(0.8);
  }
}

.pricing-shipping-col{
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.jobStatusBg {
  padding: 5px 8px 4px 8px;
  margin: 4px;
  border-radius: 15px;
  text-align: center;
  color: #fff;
  display: inline-block;
  line-height: 1.2;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 85%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background-color: $addColor !important;
  
  &.QERY {
    background-color: $menuBackgroundColor !important;
  }

  &.QREJ {
    background-color: $deleteColor !important;
  }

  &.CLOS {
    background-color: $countryBackground;
  }

  &.SEUN {
    background-color: $deleteColor !important;
  }

  &.CPRD {
    background-color: $deleteColor !important;
  }
  &.CNCL {
    background-color: $deleteColor !important;
  }
}

.jobStatusCancelled {
  padding: 5px 8px 4px 8px;
  margin: 4px;
  border-radius: 15px;
  text-align: center;
  color: #fff;
  display: inline-block;
  line-height: 1.2;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 85%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background-color: $deleteColor !important;
  
  &.QERY {
    background-color: $menuBackgroundColor !important;
  }

  &.QREJ {
    background-color: $deleteColor !important;
  }

  &.CLOS {
    background-color: $countryBackground;
  }

  &.SEUN {
    background-color: $deleteColor !important;
  }

  &.CPRD {
    background-color: $deleteColor !important;
  }
  &.CNCL {
    background-color: $deleteColor !important;
  }
}


.seun-status {
  background-color: $deleteColor !important;
}

.rejected-alert {
  border-radius: 0;
  border: 1px solid lighten($deleteColor, 10%);
  border-left: 5px solid lighten($deleteColor, 10%);
  color: $dangerBtnHoverColor;
  padding: 10px 10px 5px 10px;
  white-space: pre-wrap;
  margin-bottom: 15px;
  small {
    line-height: 1.3;
  }
}

.primary-alert {
  border-radius: 0;
  border: 1px solid lighten($primaryColor, 10%);
  border-left: 5px solid lighten($primaryColor, 10%);
  color: $headerBgColor;
  padding: 10px 10px 5px 10px;
  white-space: pre-wrap;
  margin-bottom: 15px;
  small {
    line-height: 1.3;
  }
}

.noEnquiries {
  padding: 5px;
  text-align: center;
  color: $primaryColor;
  background: $accordionBackground;
}

.btnSm.div-action {
  // padding-right: 30px;
  transform: scale(0.8);
  margin-right: -10px;
  margin-left: -10px;
}

.acceptModalShipperDetails {
  max-height: 80vh;
  min-height: 60vh;
  max-width: 60vw;
  padding: 0 15px;

  .sectionDiv {
    border: 1px solid $borderColor;
    background: #f4fbff;
    margin-bottom: 10px;
    padding-bottom: 3px;

    .col-12 {
      margin-bottom: 8px;
      padding: 0 10px;

      label {
        width: 100%;
        margin-bottom: 0;
        strong {
          width: 100%;
          display: block;
          color: #fff;
          background: $tableHeaderColor;
        }
      }

      .form-control {
        color: $primaryColor;
        border: 1px solid $borderColor;
        // padding: 5px;
        width: 100%;
        &:focus-within {
          border: 1px solid $primaryColor !important;
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}

.setting-container {
  padding: 20px;
  background: #fff;
  min-height: 100%;
  border: 1px solid #cce8fa;

  .settings_header {
    // margin-top : 20px;
    padding: 20px 15px;
    background-color: $headerBgColor;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    font-family: "Raleway";
  }

  .ratingTitlebar {
    margin: 0px;
    padding: 10px 13px;
    letter-spacing: 0.5px;
    // max-width: 800px;
  }

  .company-left-tab {
    border: 1px solid $tableAlternateBgColor;
    background: #f2faff !important;

    .nav {
      @media (max-width: 768px) {
        flex-direction: row !important;
      }
      .nav-link {
        color: $primaryColor;
        padding: 10px !important;
        border-radius: 0;
        font-weight: bold;
        &.active {
          color: #fff !important;
          background-color: $headerBgColor !important;
        }
      }
    }

    ul li.nav-item {
      text-align: center;
      // width: 100%;
      &:hover {
        background-color: transparent !important;
        color: #cce8fa !important;
      }
    }
  }

  .company-right-tab {
    border: 1px solid #cce8fa;
    min-height: 60vh;
    .tab-content {
      height: 100%;
      .tab-pane {
        height: 100%;
        .card {
          height: 100%;

          .card-body {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }

  .card {
    border: none;
    // background: none;
  }

  .withappend {
    flex-wrap: nowrap;

    .ex-work-texts {
      border-radius: 35px 0 0 35px;
    }
    .input-group-append {
      color: $primaryColor;
      .input-group-text {
        font-weight: 600;
        background-color: $primaryColor;
        color: #fff;
        border-radius: 0 35px 35px 0;
        width: 100%;
        height: 29px;
        width: 45px;
        border: 1px solid $primaryColor;
        font-family: Arial;
        font-size: 12px;
        padding: 2px 10px 0 8px;
        justify-content: center;
      }
    }
  }

  .yes_show {
    background-color: #03d162;
    color: #fff;
    padding: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-right: 5px;
  }

  .no_hide {
    background-color: #e80d3f;
    color: #fff;
    padding: 10px;
    margin-left: 5px;
    border: none;
    outline: none;
    cursor: pointer;
  }
}

.time_picker {
  width: 150px;
  .react-time-picker__clear-button:focus,
  .react-time-picker__inputGroup__input:focus {
    outline: none;
  }

  .react-time-picker__inputGroup__input,
  .react-time-picker__inputGroup__hour {
    color: $textColor;
  }

  .react-time-picker__inputGroup__input:invalid {
    background: #e80d3f;
  }

  .react-time-picker__wrapper {
    border: thin solid $primaryColor !important;
    border-radius: 5px;
    padding: 5px;
    color: $textColor;
  }
}

.file-view {
  background: $negotiateColor;
  cursor: pointer;
  color: #fff;
  margin-left: 5px;
  padding: 5px 15px !important;
  // font-size: 81%;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 4px;
  &:hover {
    background: darken($negotiateColor, 5%);
  }
}

.filteredSelect option:not([disabled]) {
  font-weight: bold;
  font-size: 110%;
  line-height: 2;
}

//AdminDashboard
.adminDashboardCard {
  .titlebar {
    // color: #0065a0;
    // color: burlywood;
    color: $primaryColor;
    font-size: 20px;
    height: 29px !important;
    padding-top: 0rem;
  }
  .card {
    background-color: #d1e8f8;
    border-radius: none !important;
    border: none;
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
      0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1),
      0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
      0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
  }
  .l-bg-cherry {
    background: linear-gradient(to right, #493240, #f09) !important;
    color: #fff;
  }

  .l-bg-blue-dark {
    background: linear-gradient(to right, #373b44, #4286f4) !important;
    color: #fff;
  }

  .l-bg-green-dark {
    background: linear-gradient(to right, #0a504a, #38ef7d) !important;
    color: #fff;
  }

  .l-bg-orange-dark {
    background: linear-gradient(to right, #a86008, #ffba56) !important;
    color: #fff;
  }

  .card .card-statistic-3 .card-icon-large .fas,
  .card .card-statistic-3 .card-icon-large .far,
  .card .card-statistic-3 .card-icon-large .fab,
  .card .card-statistic-3 .card-icon-large .fal {
    font-size: 110px;
  }

  .card .card-statistic-3 .card-icon {
    text-align: center;
    line-height: 50px;
    margin-left: 15px;
    color: #000;
    position: absolute;
    right: -5px;
    top: 20px;
    opacity: 0.1;
  }

  .l-bg-cyan {
    background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
    color: #fff;
  }

  .l-bg-green {
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%) !important;
    color: #fff;
  }

  .l-bg-orange {
    background: linear-gradient(to right, #f9900e, #ffba56) !important;
    color: #fff;
  }

  .l-bg-cyan {
    background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
    color: #fff;
  }

  .card .card-content .card-body {
    padding: 3rem !important;
    .media-body {
      // color: burlywood;
      color: $primaryColor;
    }
  }
}

//ADminDashboard

article {
  text-align: center;
}
.article {
  background: linear-gradient(to right, #095fab 10%, #25abe8 50%, #57d75b 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  // text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 1.5s linear infinite;

  h1 {
    font-size: 4vmin;
    line-height: 1.1;
    margin: 0;
  }
  p {
    margin: 0;
    font-family: "Dank Mono", ui-monospace, monospace;
    // color: burlywood;
    color: $primaryColor;
  }
  display: inline-block;
}
// @keyframes textclip {
//   to {
//     background-position: 200% center;
//   }
// }

//Admin Dashboard
.wrapper {
  max-width: 500px;
  border-radius: 12px;
  margin: 30px auto;
  padding: 30px;
}
.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #388e3c;
  margin-top: 0.8rem;
  margin-right: 1.1rem;
}
