@import "./variables.module";

.nav-link[aria-selected="true"] {
  color: $primaryColor !important;
  background-color: $borderColor !important;
  font-weight: 500;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-tabs {
  border-bottom: none !important;
  justify-content: center;
  border-top: 1px solid $borderColor;
  cursor: pointer;
  color: $primaryColor;
  font-size: 14px;
  font-weight: 600;
}

.toolTip {
  background-color: $borderColor !important;
  color: $primaryColor !important;
}

.switchs {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
  top: 18px;
  margin-left: 5%;
  margin-right: 5%;
}

.switchs input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switchsV2 {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
  top: 18px;
  margin-left: 2%;
  margin-right: 2%;
}

.switchsV2 input {
  opacity: 0;
  width: 0;
  height: 0;
}

.nav-link {
  padding: 0.5rem 4rem !important;
}

.nav-tabs .nav-link {
  border: none !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.shipping-description {
  width: 374;
  min-height: 204px;
  background-color: $borderColor;
  border: 1px solid $contentBorderColor;
  padding: 3%;
}

.shipping-description-contents {
  font-family: Arial;
  font-size: 11px;
  color: $primaryColor;
  line-height: 13px;
  p {
    font-family: Arial;
    font-size: 12px;
    color: $primaryColor;
    line-height: 15px;
  }
}

.shipping-description-contents-title {
  font-family: Arial;
  font-size: 13px;
  font-weight: 600;
  position: relative;
  padding-left: 5px;
  top: 2px;
}

.shipping-description-contents-hr {
  border: 0.5px solid $primaryColor;
  margin-top: 7px;
  margin-bottom: 15px;
}
.incoTermDescription{
  margin-top: 7px;
  margin-bottom: 15px;
  font-family: Arial;
    font-size: 1rem;
    color: #fff;
    line-height: 1rem;
    padding: 1rem;
    letter-spacing: 0.02rem;
}
.ex-works-title {
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
  color: $primaryColor;
}

.ex-works-content {
  padding-top: 8px;
}

.ex-works-hr {
  border: 0.5px solid $primaryColor;
  margin-top: 7px;
  margin-bottom: 15px;
}

.ex-works-hr-m-0 {
  border: 0.5px solid $primaryColor;
  margin-top: 0px;
  margin-bottom: 15px;
}
.disableDropdown {
  border: 1px solid $borderColor !important;
  color: $borderColor !important;
  background-image: none !important;
}

.disablePlaceholder {
  border: 1px solid $borderColor !important;
  color: $borderColor !important;
  background-image: none !important;
  &::placeholder {
    color: $borderColor !important;
  }
}
.ex-works-color {
  color: $primaryColor !important;
  border: 1px solid $primaryColor !important;
  border-radius: 28px !important;
  font-family: Arial !important;
}
.ex-works-color2 {
  background-color: #fff;
  color: $primaryColor !important;
  border: 1px solid $primaryColor !important;
  border-radius: 28px !important;
  font-family: Arial !important;
}
.ex-works-dropdown {
  border-radius: 15px;
  width: 100%;
  height: 29px;
  border: 1px solid $primaryColor;
  background-color: $btnBackgroundColor;
  font-family: Arial;
  font-size: 12px;
  padding: 5px 5px;
  color: $primaryColor;
  background-image: url("../images/arrow_down.png");
  background-position: 95% 58%;
  background-repeat: no-repeat;
  &:focus {
    outline: none;
    border: 1.5px solid $primaryColor;
    padding: 5px 5px;
    color: $primaryColor;
    background-image: url("../images/arrow_up.png");
  }
}

.ex-work-textfield {
  border-radius: 15px;
  width: 100%;
  height: 29px;
  border: 1px solid $borderColor;
  font-family: Arial;
  font-size: 12px;
  padding: 3% 0 3% 5%;
  color: $contentBorderColor;
  &:focus {
    outline: none;
    border: 1.5px solid $primaryColor;
    color: $primaryColor;
  }
}

input[type="number"] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}

.ex-work-texts {
  border-radius: 35px;
  width: 100%;
  height: 29px;
  border: 1px solid $primaryColor;
  font-family: Arial;
  font-size: 12px;
  padding: 13px 0 12px 13px;
  color: $primaryColor;

  &:focus {
    outline: none;
    border: 1.5px solid $primaryColor;
    color: $primaryColor;
  }
}


.ex-work-texts_disabled {
  border-radius: 35px;
  width: 100%;
  height: 29px;

  border: 1px solid $primaryColor;
  font-family: Arial;
  font-size: 12px;
  padding: 13px 0 12px 13px;
  color: $primaryColor;
  background-color: #ccc !important;
}

.ex-work-texts-warn {
  border-radius: 15px;
  width: 100%;
  height: 29px;
  border: 1px solid $deleteColor;
  font-family: Arial;
  font-size: 12px;
  padding: 13px 0 12px 13px;
  color: $primaryColor;
  &:focus {
    outline: none;
    border: 1px solid $deleteColor;
    color: $primaryColor;
  }
}

.ex-work-texts-warn2 {
  border-radius: 15px;
  width: 100%;
  height: 29px;
  border: 1px solid $deleteColor;
  font-family: Arial;
  font-size: 12px;
  padding: 13px 0 12px 13px;
  color: $primaryColor;
  background-color: #fff;
  &:focus {
    outline: none;
    border: 1px solid $deleteColor;
    color: $primaryColor;
  }
}
.ex-work-texts-password-warn {
  padding: 3% 2% 3% 15% !important;
  margin-left: 5px;
  border-radius: 15px;
  width: 100%;
  height: 29px;
  border: 1px solid $deleteColor;
  font-family: Arial;
  font-size: 12px;
  padding: 3% 0 3% 3%;
  color: $primaryColor;
  &:focus {
    outline: none;
    border: 1px solid $deleteColor;
    color: $primaryColor;
  }
}

.ex-works-select-warn {
  border-radius: 15px;
  width: 100%;
  height: 29px;
  border: 1px solid $deleteColor;
  font-family: Arial;
  font-size: 12px;
  color: $primaryColor;
  &:focus {
    outline: none;
    border: 1px solid $deleteColor;
    color: $primaryColor;
  }
}


.ex-works-select-warn2 {
  border-radius: 15px;
  width: 100%;
  // height: 29px;
  border: 1px solid $deleteColor;
  font-family: Arial;
  font-size: 12px;
  color: $primaryColor;
  background-color: #fff;
  &:focus {
    outline: none;
    border: 1px solid $deleteColor;
    color: $primaryColor;
  }
}

input:disabled {
  background-color: $btnBackgroundColor;
}

.middle123 {
  margin-bottom: 10px;
  padding: 20px;
}

.rateEnquiry {
  position: absolute;
  top: 25px;
  font-size: 25px;
  font-weight: bold;
  color: $primaryColor;
  font-family: "Raleway";
}

.react-tabs__tab-panel--selected {
  border: 1px solid $contentBorderColor;
  padding-top: 10px;
  padding-bottom: 10px;
}

.react-tabs__tab-list {
  background: #1278b3 !important;
  color: white !important;
}

.react-tabs__tab--disabled {
  // color: wheat !important;
  background-color: #e4e4e4 !important;
  color: #666666 !important;
}

/*------------Toggle----------------*/

.slider1 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $textColor;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:before {
    position: absolute;
    content: "";
    height: 11px;
    width: 11px;
    left: 2px;
    bottom: 1.5px;
    background-color: $btnBackgroundColor;
    transition: 0.4s;
  }
}

input:checked + .slider1 {
  background-color: $deleteColor;
}

input:focus + .slider1 {
  box-shadow: 0 0 1px $primaryColor;
}

input:checked + .slider1:before {
  left: -9px;
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}


.slider1.round1 {
  border-radius: 15px;
}


.slider1.round1:before {
  border-radius: 50%;
}

//disable style for slider 
.slider1Disabled {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $textColor;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:before {
    position: absolute;
    content: "";
    height: 11px;
    width: 11px;
    left: 2px;
    bottom: 1.5px;
    background-color: $btnBackgroundColor;
    transition: 0.4s;
  }
}
input:checked + .slider1Disabled {
  background-color: #dde2e8;
}
.slider1Disabled.round1:before {
  border-radius: 50%;
}

.slider1Disabled.round1 {
  border-radius: 15px;
}

.pdfUploader {
  border: none;
  width: 100%;
  font-size: 12px;
}

.pdfText {
  font-family: Arial;
  font-size: 12px;
  margin-right: -3px;
  margin-bottom: 3px;
  margin-top: -8px;
  line-height: 1.3;
}
.msdsLabel {
  color: $primaryColor;
  font-family: Arial;
  font-size: 12px;
  margin-right: -3px;
  margin-bottom: 3px;
  margin-top: -8px;
  line-height: 1.3;
}

/*--------------------------------------------------------------*/

.inputfile-box {
  position: relative;
}

.inputfile-box-error {
  position: relative;
  border: 1px solid red;
  border-radius: 16px;
  height: 29px;
}

.inputfile {
  display: none;
}

.file-box {
  display: inline-block;
  // max-width:300px;
  width: 100%;
  margin-top: -3px;
  border: 1px solid;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 6px;
  padding-top: 2px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 15px;
  height: 25px;
  margin-bottom: 0px !important;
}

.file-button {
  background-color: $borderColor;
  position: absolute;
  padding-right: 5px;
  padding-left: 5px;
  top: -3px;
  right: 0px;
  cursor: pointer;
}

.label-file-css {
  margin-bottom: 0 !important;
  width: 100%;

  .file-box.hazardousFile {
    width: 100% !important;
  }

  .file-button {
    border-color: $primaryColor;
    border-width: 1px 1px 1px 0;
    border-style: solid;
    border-radius: 0 15px 15px 0;
  }
}

textarea::placeholder {
  color: $primaryColor;
  padding: 1%;
}

sup {
  font-size: 60% !important;
  top: -1em !important;
}

.file-button-hazardous {
  background-color: $borderColor;
  position: absolute;
  padding-right: 5px;
  padding-left: 5px;
  top: -3px;
  right: 5px;
  cursor: pointer;
}

.addPositionBtn {
  top: 20px;
  float: right;
  position: relative;
}

.addPositionBtn1 {
  margin-top: 10px;
  text-align: right;
}

.telephoneAddBtn {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

//disable Text
.disableText{
  color: #dde2e8 !important;
}