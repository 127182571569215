@import './variables.module';

body {
  line-height: 1.3;
}

.my-modal {
  width: 90vw;
  /* Occupy the 90% of the screen width */
  max-width: 90vw;
}

.nw {
  white-space: nowrap;
}

.login-form {
  width: 340px;
  position: absolute;
  left: 9vw;
  top: 15vh;
  padding-top: 17vh;
  z-index: 10;
}
.bold{
  font-weight: bold;
}
.login-form form {
  margin-bottom: 15px;
  border: 0.75px solid $whiteColor;
  border-radius: 41px;
  width: 306px;
  height: 269px;
  padding: 18px;
}

  // .mentionInp__suggestions{
  //   background-color: aqua;
  //   z-index: 99 !important;
  // }

  
  .mentionInp__suggestions__list {
    background-color: rgb(211, 223, 241);
    border: 1px solid rgba(0, 0, 0, 0.15);
    left: 0;
    bottom: 100%;
    /* font-size: 10pt; */
  }
  
  .mentionInp__suggestions__item {
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  
  .mentionInp__suggestions__item--focused {
    background-color: #cee4e5;
  }
.form-control {
  min-height: 30px;
  border-radius: 2px;

  &::placeholder {
    color: $primaryColor;
  }
}

.landingLogin {
  // background-image: URL("../images/login.png");
  position: fixed;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.login_bg_img_div {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
}

.bg_img_shape {
  position: relative;
  position: relative;
  z-index: 10;
  left: 0%;
  height: 100%;
  object-fit: cover;
}

.bg_img {
  position: relative;
  z-index: 9;
  top: 100%;
  left: 0%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.landing {
  background-image: URL('../images/login.png');
  position: fixed;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.landingNotFound {
  z-index: -10;
  background-image: URL('../images/login.png');
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
}

.notFoundBackBtn {
  position: absolute;
  top: 100px;
  left: 100px;
  font-size: 1.2rem;
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  z-index: 0;
}

.impexo-white {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  // background: $menuBackgroundColor;
  background-color: transparent;
  top: -64px;
  padding: 0 15px;
  margin-bottom: 15px;
  width: 87%;
  height: auto;
}

.login-textfield {
  top: -45px;
  width: 268px !important;
  height: 36px !important;
  position: relative;
}

.customLoginBtnCenter {
  position: relative;
  text-align: center;
  top: -45px;
}

.clearfix {
  text-align: center;
}

.forgotpsw {
  color: $whiteColor;
  position: relative;
  top: -45px;

  &:hover {
    text-decoration: underline;
    color: $whiteColor;
    cursor: pointer;
  }
}

.logisticText {
  position: absolute;
  top: 40vh;
  left: 52vw;
  z-index: 10;
  width: 46%;
  height: 18vh;
  display: flex;
  align-items: center;
  justify-content: center;

  // @media(min-width:1440px) {
  //   left: 52vw;
  // }
}

.logisticText>p {
  text-transform: uppercase;
  color: #fff;
  padding: 2vw;
  font-family: sans-serif;
  width: 100%;
}

// .logisticText > p > strong {
//   display: flex;
//     justify-content: space-around;
//     flex-direction: column;
// }

.start-txt-loginPage {
  color: #ebe1ee;
}

.mid-txt-loginPage {
  color: #ebe1ee;
  font-size: 3vh;
}

.end-txt-loginPage {
  font-size: 5vh;
  margin-left: 6%;
}

.notfoundcontainer {
  background-color: black;
  height: 657px;
}

.notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  h1 {
    color: #ecf0f1;
    text-shadow: 0px 2px 0px rgba(150, 150, 150, 1);
  }

  p {
    color: rgba(189, 195, 199, 1);
    font-weight: bold;
  }
}

.eyes {
  width: 5em;
  height: 5em;
  border-radius: 50%;
  background-color: $whiteColor;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: blinking 3s ease-out 2 5s;
}

#eye-container {
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
}

.pupils {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: absolute;
  background-color: black;
  animation: moving-pups 2.5s cubic-bezier(0.43, -0.45, 0.45, 1.42) infinite;
}

@keyframes moving-pups {
  0% {
    left: 14%;
  }

  50% {
    left: 70%;
  }

  100% {
    left: 14%;
  }
}

@keyframes blinking {
  0% {
    transform: rotateX(0deg);
  }

  20% {
    transform: rotateX(90deg);
  }

  60% {
    transform: rotateX(45deg);
  }

  70% {
    transform: rotateX(30deg);
  }

  80% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

.contactDetailProfile {
  border: 1px solid $borderColor;
  margin: 5px;
  margin-top: 0;
  padding: 10px;
  // box-shadow: 2px 3px 3px gba(0, 0, 0, 0.33);
}

.companyDetailContactBox {
  border: 1px solid $borderColor;
  margin-left: -11px;
  margin-right: -11px;
}

.attFileBoxCompanyProfile {
  border: 1px solid $borderColor;
  padding: 5px 0px;
  margin-left: 4px;
  margin-right: 4px;
}

.hazardousFile {
  width: 233px !important;
  padding-left: 6px !important;
  padding-top: 4px !important;
  height: 29px !important;
}

//scrollbar
::-webkit-scrollbar-track {
  padding: 2px 0;
  background-color: #67a7cf;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $accordionBackground;
  border: 1px solid grey;
}

.DefaultPaddingLeft {
  padding-left: 22px !important;
}

.customerTierKyc {
  width: 80px !important;
}

.networkTier {
  width: 95px !important;
  color: $deleteColor !important;
}

.assignTier {
  width: 125px !important;
}

.editTier {
  padding-left: 33px !important;
}

.css-1wa3eu0-placeholder {
  color: $primaryColor !important;
  font-family: Arial !important;
  font-size: 12px !important;
  line-height: 1;
  // top: 42% !important;
}

.select__control.select__control--is-focused.css-1pahdxg-control {
  max-height: 28px !important;
  min-height: 28px !important;
}

.css-1fhf3k1-control {
  border-radius: 28px !important;
  border: 1px solid $primaryColor;
  max-height: 28px !important;
  min-height: 28px !important;
}

.css-1hwfws3 {
  margin-top: -7px;
}

.select__value-container.css-g1d714-ValueContainer {
  margin-top: -5px;
}

.select__indicator {
  padding: 5px 8px 11px 8px !important;
}

.css-6q0nyr-Svg,
.css-19bqh2r,
.select__input {
  color: $primaryColor !important;
  fill: $primaryColor !important;
}

.select__menu {
  color: $primaryColor !important;
  margin-top: 2px !important;
}

.css-1okebmr-indicatorSeparator,
.css-109onse-indicatorSeparator {
  background-color: $primaryColor !important;
  margin-bottom: 12px !important;
  margin-top: 6px !important;
}

.css-tlfecz-indicatorContainer {
  color: $primaryColor !important;
}

.tableSelect {
  .css-2b097c-container {
    width: 100%;
    min-width: 300px;
    border: 1px solid rgb(192, 192, 192);
  }

  .css-yk16xz-control {
    border-radius: 4px;
    border: 1px solid $primaryColor;
    height: 28px !important;
    // border-radius: 28px !important;
  }
}

.select__control {
  border-radius: 24px !important;
}

.adjustPaddingLeft {
  padding-left: 0px;
}

.adjustPaddingRight {
  padding-right: 0px;
}

//For Crud Container new
.mainContainer {
  background-color: $whiteColor;
  border-bottom: 2px solid $headerBgColor;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.emailAndSMSContainer {
  height: auto;
}

.panelColumn1 {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  transition: 0.5s;
}

.panelColumn2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  transition: 0.5s;
}

@media all and (max-width: 768px) {

  // .panelColumn1 {
  //   max-width: 80%;
  // }
  // .panelColumn2 {
  //   max-width: 20%;
  // }
  .notificationAll {
    .notification-item {
      flex-direction: column;
      align-items: end !important;

      h4 {
        font-size: 16px !important;
      }

      p {
        font-size: 12px !important;
      }
    }
  }

  // .notification-item {
  //   color: rgb(59, 59, 59);
  //   padding: 10px 30px;
  //   margin-bottom: 20px;
  //   border-left: 3px solid #0065a0;
  //   display: flex;
  // // flex-direction: column;
  // justify-content: space-between;
  // align-items: center;
  // box-shadow: #0065a015 0px 1px 3px 0px, #d3dfe715 0px 1px 2px 0px;

  // }
}

.searchPanelHeader {
  text-align: center;
  padding: 8px 5px;
  cursor: pointer;
  background: $headerBgColor;
  font-weight: bold;
  font-family: 'Raleway';
  font-size: 16px;
  color: $whiteColor;
  letter-spacing: 1.5px;
  width: 100%;
}

.marginNew {
  margin-top: 5px;
  margin-bottom: 5px;
}

.upRightSearch {
  writing-mode: tb-rl;
  margin: auto;
  display: block;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Raleway';
  font-size: 20px;
  letter-spacing: 1px;
  color: $whiteColor;
  letter-spacing: 3.5px;
  height: inherit;
  margin-top: 0 !important;

  &.rotate {
    transform: rotate(180deg);
  }
}

//Pagination

.paginationLogistic {
  display: flex;
  justify-content: center;
}

.page-item.active .page-link {
  z-index: 3;
  color: $whiteColor !important;
  background-color: $headerBgColor !important;
}

.page-link {
  cursor: pointer;

  &:hover {
    z-index: 2;
    color: $whiteColor !important;
    text-decoration: none;
    background-color: $headerBgColor !important;
  }
}

.page-item.disabled .page-link {
  color: #6c757d !important;
  pointer-events: none;
  cursor: auto;
  background-color: $whiteColor;
  border-color: #dee2e6;
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: 0.75rem auto !important;
  }
}

.companyShortNameDisplay {
  padding-left: 5px;
  color: $primaryColor;
}

.userTextField {
  border-radius: 35px;
  width: 100%;
  height: 29px;
  border: 1px solid $primaryColor;
  font-size: 12px;
  color: $primaryColor;
  display: flex;
  align-items: center;
}

.userTextField-warn {
  border-radius: 35px;
  width: 100%;
  height: 29px;
  border: 1px solid red;
  font-size: 12px;
  color: $primaryColor;
  display: flex;
  align-items: center;
}

.error-border {
  @extend .userTextField;
  border: 1px solid $deleteColor;
}

.pass_warning {
  border-radius: 35px;
  width: 100%;
  height: 29px;
  border: 1px solid $deleteColor;
  font-size: 12px;
  color: $primaryColor;
  display: flex;
  align-items: center;
}

.departmentStaffLoginUserField {
  border: none;
  width: 100%;
  outline: none;
  color: $primaryColor;
}

.override-chip {
  background-color: $primaryColor !important;
  margin-bottom: 5px;
  border: 0;
}

.override-success {
  background-color: $addColor !important;
  margin-bottom: 5px;
  border: 0;
}

//Company Profile
.companyProfileAddBody {
  height: 72.8vh;
  overflow-x: hidden;
  overflow-y: auto;
  border-bottom: 1px solid $borderColor;
}

//Read more
.readMoreCSS {
  p {
    word-break: break-word;
    white-space: pre-line;
    hyphens: auto;
    padding: 5px;
    margin-bottom: 0;
    text-align: left;
    margin-left: auto;
    display: block;
  }

  .clamp-lines__button {
    border: none;
    background: transparent;
    color: $headerBgColor;
    font-weight: 600;
    font-size: 13px;

    &:focus,
    &:visited,
    &:active {
      border: none;
      outline: none;
    }
  }
}

//Modal Max-width
.manageModalMaxWidthWithFooter {
  max-height: 75.2vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.manageModalMaxWidth {
  max-height: 84.2vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.customerKycHeader {
  font-family: 'Raleway';
  color: $whiteColor;
  font-weight: 800;
  font-size: 20px;
  letter-spacing: 1px;
  padding: 6px;
}

//Notification
.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $whiteColor;
  font-size: 17px;
  position: relative;
  margin-right: 10px;
  cursor: pointer;

  &:focus,
  &:hover,
  &:visited {
    outline: none !important;
  }

  .notificationBadge {
    font-size: 10px;
    background: $deleteColor;
    padding: 0px;
    padding-left: 4px;
    padding-right: 5px;
    border-radius: 48px;
    position: absolute;
    right: -3px;
    top: 3px;
    font-weight: bold;
    transition: 0.3s;
  }

  .fadeBadge {
    opacity: 0;
  }

  .notificationPanel {
    background-color: $whiteColor;
    box-shadow: 2px 3px 3px $boxShadowColor;
    overflow: visible;
    position: absolute;
    top: 45px;
    margin-right: 178px;
    width: 340px;
    z-index: 10;
    height: 0;
    opacity: 0;
    transition: 0.3s;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      color: $blackColor;
      border: 13px solid $blackColor;
      border-color: transparent transparent $whiteColor;
      margin-top: -26px;
      margin-left: 246px;
      z-index: 99999;
    }
  }

  .panelHeight {
    height: 540px;
    opacity: 1;
    border: 1px solid $borderColor;
  }

  .panelHeightSafari {
    height: 400px;
    opacity: 1;
    border: 1px solid $borderColor;
    right: -252px;
  }

  .notificationPanelBody {
    position: relative;
    color: $primaryColor;
    padding: 5px;
    height: 100%;
    overflow: hidden;

    .notificationTitle {
      font-weight: bold;
      padding: 5px;
      font-size: 14px;
      font-family: 'Raleway';
      background-color: $whiteColor;
      border-bottom: 1px solid #dddddd;
    }

    .notificationBody {
      height: 480px;
      font-size: 14px;
      overflow-x: hidden;
      overflow-y: auto;
      margin-left: -5px;
      margin-right: -5px;

      &::-webkit-scrollbar {
        width: 5px;
      }

      .notificationPanelDataTemplateHolder {
        padding-top: 8px;

        // &:nth-child(even) {
        //   background-color: $tableAlternateBgColor;
        // }

        .notification-panel-item {
          border-left: 3px solid #0065a0;
          padding: 2px 8px;
          margin-bottom: 10px;
        }

        .notifyMessageTitle {
          font-weight: bold;
          font-size: 13px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .notifyMessageText {
          font-size: 12px;
        }
      }
    }

    .notificationFooter {
      position: absolute;
      width: 100%;
      bottom: 0px;
      z-index: 2;
      background-color: $headerBgColor;
      text-align: center;
      font-weight: bold;
      color: white;
      padding: 8px;
      font-size: 14px;
      font-weight: bold;
      border-top: 1px solid #dddddd;
      margin-left: -5px;
      margin-right: -5px;

      a {
        color: white;
      }
    }
  }
}

.inviteBtnWidth {
  width: 110px !important;
}

//All Notifications

.notificationAll {
  background-color: white;
  padding-bottom: 50px;
  // padding: 40px;
  min-height: 100vh;

  .header-div-notification {
    background-color: #0065a0;

    .container {
      background-color: #0065a0;
    }
  }

  .all-notification-header {
    position: relative;
    padding: 4px 40px;
    // padding: 20px 40px;
    color: white;
    text-transform: uppercase;
    margin-bottom: 40px;

    h1 {
      font-size: 1.6rem;
    }
  }

  .all-notification-header h1:not(.notification-page)::after {
    content: ' ';
    position: absolute;
    left: 30px;
    bottom: -25px;
    border-top: 30px solid #0065a0;
    border-right: 30px solid transparent;
    border-left: 30px solid transparent;
    border-bottom: none;
    height: 10px;
    width: 10px;
  }

  .notification-item {
    text-decoration: none;
    cursor: pointer;
    color: rgb(59, 59, 59);
    padding: 10px 15px 8px 15px;
    margin-bottom: 20px;
    border-left: 3px solid #0065a0;
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow:
      #0065a015 0px 1px 3px 0px,
      #d3dfe715 0px 1px 2px 0px;

    .notification-item-details {
      width: 80%;
    }

    h4 {
      font-weight: 400;
      font-size: 20px;
    }

    p {
      font-size: 13px;
    }
  }

  .notification-item:hover {
    box-shadow:
      #002d4751 0px 1px 3px 0px,
      #99d0f542 0px 1px 2px 0px;
  }

  .unread-notification {
    background-color: #0065a015;

    h4 {
      font-weight: 600;
    }
  }
}

//Spinner
.fullWindow-Spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
  color: $whiteColor;

  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
}

.react-multi-email [data-tag] {
  background-color: $tableAlternateBgColor !important;
  color: $primaryColor !important;
}

//Ck editor
.ck.ck-editor__main>.ck-editor__editable {
  height: 320px;
}

.editor-Chip {
  background-color: $tableAlternateBgColor;
  color: $primaryColor;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  padding: 3px 13px;
  cursor: pointer;
  margin-bottom: 5px;

  &:hover {
    box-shadow: 1px 1px 3px $boxShadowColor;
  }
}

.rateQueryTitle {
  color: $primaryColor;
}

.rateEnquiryContainer {
  border: 1px solid $borderColor;
  // box-shadow: 2px 3px 3px $boxShadowColor;
  margin-top: 6px;
  color: $primaryColor;
  text-align: center;
  overflow: auto;
}

//Tabs
.rwt__tab {
  font-family: 'Raleway';
  padding: 5px 15px !important;
  margin-top: 5px;
  color: $primaryColor;
  user-select: none;
  min-width: 120px;
}

.rwt__tab:hover,
.rwt__tab:focus {
  outline: 0;
  background-color: unset !important;
}

.rwt__tablist:not([aria-orientation='vertical']) .rwt__tab[aria-selected='true']:after {
  border-bottom: 3px solid $headerBgColor !important;
}

.rwt__tablist:not([aria-orientation='vertical']) .rwt__tab[aria-selected='true'] {
  font-weight: bold;
  padding: 5px 13px !important;
}

.customerKyc_toggle {
  float: right;
  display: flex;
  align-items: center;
  height: 36px;
  color: $primaryColor;

  .switch {
    top: 4px;

    .slider:before {
      top: 2px;
    }

    input:checked+.slider {
      background-color: $primaryColor;
    }

    .slider {
      background-color: $deleteColor;
    }
  }
}

.superAdmin_toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  color: $primaryColor;

  .switch {
    top: 4px;

    .slider:before {
      top: 2px;
    }

    input:checked+.slider {
      background-color: $primaryColor;
    }

    .slider {
      background-color: $deleteColor;
    }
  }
}

.superAdminContainer {
  text-align: center;
}

.superAdminContainer .col {
  background-color: #0170b4;
  padding: 20px;
  border: 2px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: #0084d4;
  }
}

.rateEnquiry_toggle {
  display: flex;
  float: right;
  justify-content: center;
  align-items: center;
  height: 36px;
  color: $primaryColor;

  .switch {
    top: 4px;

    .slider:before {
      top: 2px;
    }

    input:checked+.slider {
      background-color: $primaryColor;
    }

    .slider {
      background-color: $addColor;
    }
  }
}

.rateEnquiry_toggle2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  color: $primaryColor;

  .switch {
    top: 4px;

    .slider:before {
      top: 2px;
    }

    input:checked+.slider {
      background-color: $deleteColor;
    }

    .slider {
      background-color: $primaryColor;
    }
  }
}

.textColor {
  color: $primaryColor;
}

.sentEmailDivHolder {
  border: 1px solid $borderColor;
  box-shadow: 2px 3px 3px $boxShadowColor;
  max-height: 81vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.emailInviteChip {
  padding: 3px 8px;
  color: $whiteColor;
  border-radius: 43px;
}

.approvalChip {
  margin-right: 10px;
  font-size: 14px;
  padding: 6px 19px !important;
}

.acceptedColor {
  background-color: $addColor;
}

.pendingColor {
  background-color: $primaryColor;
}

.maleIcon {
  width: 20px;
  height: 20px;
}

.rejectedColor {
  background-color: $deleteColor;
}

.sendTermCondition {
  width: 220px !important;
}

.indicate-error {
  border: 1px solid $dangerBtnColor !important;
}

.indicate-select-error {
  border: 1px solid $dangerBtnColor !important;
  border-radius: 28px !important;
}

.credit_display_box {
  text-align: left;
  height: 0;
  transition: 0.2s;
  overflow: hidden;
}

.credit_display_box_show {
  min-height: 60px;
  height: auto;
  padding: 10px;
  border: 1px solid #dfdfdf;
  margin-top: 10px;
  box-shadow: 1px 1px 2px $boxShadowColor;
}

//Terms and Condition
.termAndConditionDisplayBox {
  border: 1px solid $borderColor;
  min-height: 50vh;
  max-height: 50vh;
  overflow: auto;
  padding: 15px;
  box-shadow: inset 0 0 10px $boxShadowColor;
  word-break: break-word;
}

.termConditionBody {
  border: 1px solid $borderColor;
  padding: 10px 15px;
  margin-top: 5px;

  p {
    text-align: justify;
  }
}

.termsCheckbox {
  margin-bottom: 5px;
  position: relative;
  top: -4px;
  left: 10px;
}

.termsLogOut {
  position: absolute;
  right: 0;
  top: 6px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.logistic-background-terms {
  background-image: URL('../images/login-bg.png');
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;

  .TC-Box_holder {
    height: 90vh;
    align-items: center;
    display: flex;
    justify-content: center;

    .TC-Box {
      display: flex;
      background: $whiteColor;
      flex-direction: column;
      padding: 20px 20px 20px 20px;
      box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.8);
      border: 2px solid #ccc;

      .warningTermsIcon {
        color: $goldenHover;
        font-size: 60px;
      }
    }
  }
}

.sendAgain {
  width: 145px !important;
}

.boxShadow {
  box-shadow: 2px 3px 3px $boxShadowColor;
}

.containing-table {
  display: table;
}

.content {
  display: table-cell;
  vertical-align: middle;
}

.scrollable {
  overflow-x: scroll;
}

// Chip Customization
.acceptChip {
  // font-weight: bold;
  background-color: $tableHeaderColor;
  border-radius: 28px;
  color: #0084d4;
  outline: none;
}

.pendingChip {
  @extend .acceptChip;
  background-color: $deleteColor;
}

.fontColor {
  color: $whiteColor;
}

.paddingCss {
  padding-right: 40px;
}

.raleway-text {
  font-family: 'Raleway';
}

.dateOfBirth {
  width: 100%;
}

.dynamicFieldBtn {
  border-radius: 50%;
  background-color: #0084d4;
  border: none;
  margin-right: 10px;
}

.requestQuoteBtn {
  display: flex;
  margin-left: auto;
  text-transform: uppercase;
  height: 40px;
  background-color: $primaryColor;
  border: 1px solid $primaryColor;
  color: $whiteColor;
  align-items: center;

  span {
    margin-right: 15px;
  }

  .requestQuoteIcon {
    font-size: 25px;
  }

  &:hover {
    font-weight: bold;
    color: $primaryColor;
    background-color: $whiteColor;
  }
}

.salesVisitTable {
  table-layout: fixed;

  tbody {
    td {
      white-space: pre-line !important;
    }
  }
}

//Rate Enquiry
.potentialCustomerBox {
  text-align: left;
  padding: 5px 10px !important;
  margin: 0 !important;
  font-weight: bold;
}

.potentialCustomerFieldBox {
  border: 1px solid $contentBorderColor;
  border-top: none;
}

.remarksPotentialCustomer {
  border-color: $contentBorderColor !important;

  &::placeholder {
    color: $primaryColor !important;
  }
}

// contain-backgroud-img
.bg-full-cover {
  background-image: linear-gradient(0, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)),
    URL('../images/login-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

//mandatory field
.mandatoryField {
  color: red;
}

.notification-period {
  font-size: 0.8rem !important;
  text-align: end !important;
}

.response-stat-div {
  background: #0065a0;
  width: 100%;
  height: auto;
  padding: 2px;
  min-width: 80px;
  max-width: 80px;

  @media (min-width: 1024px) {
    min-width: 80px;
  }

  @media (max-width: 1024px) {
    min-width: 65px;
  }
}

.response-stats {
  font-size: 0.8rem !important;
  color: white;
  line-height: 10px;
}

.staff-total {
  font-size: 1.1rem;
  color: $primaryColor;
}

.fixed-height-contatiner {
  height: 85vh !important;
}

.fixed-height-contatiner-rate-wizard {
  // height: 84vh !important;
  // commented this as rate-enquiry height was not taking full height and affedcted chat-container position in rate wizard module
}

.content-stats {
  background-color: $contentBorderColor;
  padding: 2px;
}

.status-history-panel {
  height: 625px !important;
  overflow-y: scroll;
}

.rate-enquiry-files-panel {
  height: auto;
  // height: 205px !important;
  // overflow-y: scroll;
}

.detailGoBackBtn {
  position: sticky;
  right: 80px;
  // top: 75px;
  // bottom:10px;
  // cursor:pointer;
  width: 100%;
  // height:150px;
  // background-color:white;
  // text-indent:-9999px;
  // display:none;
  // -webkit-border-radius:60px;
  // -moz-border-radius:60px;
  // border-radius:60px
  z-index: 100;
}

.detailGoBackBtn #back {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -8px;
  margin-top: -12px;
  height: 0;
  width: 0;
  border: 8px solid transparent;
  border-bottom-color: #ffffff;
}

.customButton {
  padding: 10px 20px;
  background-color: #f2f2f2;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition:
    transform 0.2s,
    box-shadow 0.2s;
}

.customButton:active {
  transform: translateY(1px);
  box-shadow: none;
}

.customButton:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.badge-space {
  .date-range {
    position: relative;

    label {
      position: absolute;
      top: -28px;
      font-size: 12px;
      left: 10px;
    }

    input {
      border-radius: 25px;
      background: transparent;
      border: 1px solid #007bff;
      color: #537283;
    }
  }

  .badge-primary {
    background-color: transparent;
    color: #007bff;
    border: 1px solid #007bff;
  }

  .active-badge {
    background-color: #007bff;
    color: white;
  }

  .badge:hover {
    background-color: #007bff;
    color: white;
  }

  .badge {
    font-weight: 400;
    padding: 8px 20px;
    border-radius: 25px;
    cursor: pointer;
    position: relative;
    text-transform: capitalize;

    .unread-count {
      background-color: red;
      color: white;
      position: absolute;
      top: -10px;
      right: -12px;
      // top: -20px;
      // right: -2px;
      padding: 5px;
      border-radius: 50%;
      font-weight: 600;
      font-size: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 25px;
      width: 25px;
    }
  }
}

//news section

.section-news-home {
  background: white;
  padding: 50px 80px;
  min-height: 100%; // height: 100vh;
  // overflow: scroll;
}

.section-news-home .news-container .news-page-card {
  background: #ffffff;
  overflow: hidden;
  height: 380px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0px 0px 3px rgba(163, 163, 163, 0.562);
  box-shadow: 0px 0px 3px rgba(163, 163, 163, 0.562);
  margin-bottom: 30px;

  .img-news-container {
    text-align: center;
    height: 300px;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }
  }

  .news-content-lg {
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10px 15px;
    background: $primaryColor;

    h3 {
      font-weight: 600;
      color: #fff;
      text-transform: uppercase;
      font-size: 18px;
      margin-top: 1vh;
    }

    p {
      color: #ffffff;
      font-weight: 300;
    }

    span {
      margin-top: auto;
      color: #fff;
      font-size: 12px;
    }
  }

  .news-link {
    display: none;
    height: 100%;
    width: 0%;
    color: #aaa;
    background-color: #212121;
    position: absolute;
    top: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    text-align: center;
    // padding-top: 180px;
  }
}

.news-link span {
  text-align: center;
  max-height: 400px;
  border: 1px solid #fff;
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  font-weight: 200;
  transition: 0.3s;
  text-transform: uppercase;
  font-size: 12px;

  &:hover {
    color: black;
    background-color: white;
  }
}

.news-link h3 {
  font-size: 18px;
}

.news-h1 {
  text-transform: uppercase;
  // background: #0084d4;
  // padding: 0px 15px;
  display: inline-block;
  color: #0084d4;
  // line-height: 1.5;
  margin-bottom: 20px;
  font-size: 28px;
}

.banner-news-container {
  // margin-bottom: 10rem;

  .banner-row {
    margin: 0;
  }

  .banner-news {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 250px;

    // height: 250px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .news-link {
      height: 100%;
      width: 0%;
      color: white;
      background-color: #212121;
      position: absolute;
      top: 0;
      opacity: 0;
      -webkit-transition: all 0.4s ease-out 0s;
      transition: all 0.4s ease-out 0s;
      text-align: center;
      // padding-top: 80px;
      padding: 20px;

      h1 {
        font-size: 1.25rem;
      }
    }

    .banner-news-content {
      position: absolute;
      bottom: 15%;

      h1 {
        text-transform: uppercase;
        // background: #d6d6d6;
        padding: 0px 15px;
        display: inline-block;
        color: white;
        // line-height: 1.5;
        // margin-bottom: 10px;
      }

      p {
        color: white;
        padding: 0px 15px;
        text-shadow: 2px 2px black;
      }
    }
  }
}

.banner-news-container .banner-news:hover .news-link {
  height: 100%;
  width: 100%;
  color: white;
  background-color: #212121d0;
  position: absolute;
  top: 0;
  opacity: 0.95;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.news-banner-box {
  padding-right: 0;
  padding-left: 0;
}

$desktop: 1080px;

@mixin breakpoint($point) {
  @if $point ==desktop {
    @media (min-width: $desktop) {
      @content;
    }
  }
}

.news-container-new {
  // display: flex;
  margin: 20px 0;
}

.container-news {
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  width: 100%;
  // margin: 20px 0 80px;
  justify-content: space-around;
  gap: 30px;
}

.news-container-new .card {
  width: 250px;
  height: 380px;
  // margin: 10px;
  padding: 0;
  border: none;
  background-color: white;

  // box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
  img {
    height: 130%;
    // width: 100%;
    object-fit: cover;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .dateSpan {
    position: absolute;
    background: #0083d44e;
    z-index: 100;
    padding: 5px 10px;
    color: white;
    top: 20px;
    font-weight: 100;
    font-style: italic;
    font-size: 12px;
  }

  .card__thumb {
    margin: 0;
    height: 100%;
  }

  &:hover {
    .card__caption {
      top: 50%;
      transform: translateY(-50%);
    }

    .card__image {
      transform: translateY(-10px);
    }

    .card__thumb {
      &::after {
        top: 0;
      }
    }

    .card__snippet {
      margin: 20px 0;
    }
  }

  &__thumb {
    position: relative;
    max-height: 400px;
    overflow: hidden;

    @include breakpoint(desktop) {
      max-height: 500px;
    }

    &::after {
      position: absolute;
      top: 0;
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 40%, rgba(255, 255, 255, 0) 100%);
      transition: 0.3s;

      @include breakpoint(desktop) {
        top: calc(100% - 140px);
      }
    }
  }

  &__image {
    transition: 0.5s ease-in-out;
  }

  &__caption {
    position: absolute;
    top: 50%;
    z-index: 1;
    padding: 0 20px;
    color: white;
    transform: translateY(-50%);
    text-align: center;
    transition: 0.3s;
    width: 100%;

    @include breakpoint(desktop) {
      top: calc(100% - 110px);
      transform: unset;
    }
  }

  &__title {
    display: -webkit-box;
    max-height: 85px;
    overflow: hidden;
    font-size: 18px;
    line-height: 28px;
    text-shadow: 0px 1px 5px black;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &__snippet {
    display: -webkit-box;
    max-height: 150px;
    margin: 20px 0;
    overflow: hidden;
    font-size: 12px;
    line-height: 20px;
    text-overflow: ellipsis;
    transition: 0.5s ease-in-out;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

    @include breakpoint(desktop) {
      margin: 150px 0;
    }
  }

  &__button {
    display: inline-block;
    padding: 10px 20px;
    color: white;
    border: 1px solid white;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      color: black;
      background-color: white;
    }
  }
}

.news-category {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  // flex-wrap: wrap;

  .news-grid {
    width: 70%;
  }

  .news-category-list {
    width: 30%;
  }
}

@media (max-width: 1000px) {
  .news-category {
    flex-wrap: wrap;

    .news-grid {
      width: 100%;
    }

    .news-category-list {
      width: 100%;
    }
  }
}

.notificationArea {
  .input {
    max-width: 190px;
    background-color: #f5f5f5;
    color: #242424;
    padding: 0.15rem 0.5rem;
    min-height: 35px;
    border-radius: 4px;
    outline: none;
    border: none;
    line-height: 1.15;
    box-shadow: 0px 10px 20px -18px;
    margin-right: 10px;
  }

  input:focus {
    // border-bottom: 2px solid #5b5fc7;
    // border-radius: 4px 4px 2px 2px;
  }

  input:hover {
    outline: 1px solid lightgrey;
  }
}

.news-cat-sm {
  display: flex;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 2px rgba(241, 241, 241, 0.834);
  padding: 10px;
  gap: 10px;
  margin-bottom: 10px;
  // justify-content: space-around;
  align-items: center;
  transition: 0.2s;

  &:hover {
    box-shadow: 2px 3px 6px 2px rgba(220, 220, 220, 0.834);
  }

  .news-img {
    height: 80px;
    width: 25%;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      // position: absolute;
    }
  }

  .news-cat-details {
    width: 75%;

    p {
      margin: 5px 0px;
      font-size: 16px;
      font-weight: 600;
    }

    .news-cat-link {
      text-decoration: none;
      color: black;
    }
  }
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp-1 {
  display: -webkit-box;
  max-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.lineClamp-2 {
  display: -webkit-box;
  max-height: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.lineClamp-3 {
  display: -webkit-box;
  max-height: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.lineClamp-4 {
  display: -webkit-box;
  max-height: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.carousel-setting {
  height: 500px;

  img {
    height: 100%;
    // width: 100%;
    object-fit: contain;
  }

  .carouselCaption {
    position: absolute;
    left: 10%;
    bottom: 9%;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 15px 10% 15px 30px;
    width: 55%;
    border-radius: 0.5rem;
    box-shadow:
      0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
      0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
  }
}

.post-content-section {
  // padding: 0 0 100px 0;
  background-color: white;
  margin: 0;
  padding-bottom: 10px;

  .container-page {}

  .post-title-block {
    // background: URL(https://static.pexels.com/photos/268455/pexels-photo-268455.jpeg);
    // padding: 130px 0;
    height: 350px;

    overflow: hidden;
    text-align: center;
    background-size: fill !important;
    $bbg: rgb(53, 53, 53);
    color: #fff;
    position: relative;
    background-repeat: no-repeat !important;
    // background-size: cover !important;
    // z-index: 1;
    position: relative;

    img {
      position: absolute;
      left: 0;
      right: 0;
      transform: translateY(-20%);
    }

    &::after {
      -webkit-backdrop-filter: blur(3px);
      /* Use for Safari 9+, Edge 17+ (not a mistake) and iOS Safari 9.2+ */
      backdrop-filter: blur(2px);
      /* Supported in Chrome 76 */
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.425);
    }

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      //   position: relative;
      z-index: 1;
      padding: 35px 30px;
      line-height: 1.1;
      font-size: clamp(28px, 3vw, 60px);
      margin-bottom: 0px;
      text-transform: capitalize;
    }

    span {
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      //   position: relative;
      z-index: 1;
      font-size: 20px;
    }
  }

  .post-content-block {
    padding: 10px 60px;

    ul {
      list-style: square;
      margin-left: 20px;
    }

    .image-block {
      padding: 20px 20px 20px 0;
    }
  }

  .side-t {
    margin-top: 15px;
    // background: rgb(236, 236, 236);
    font-size: 24px;
    // padding: 4px 10px 4px 0;
    text-transform: uppercase;
    // line-height: 1.7;
  }

  .recent-p {
    .list-group-item {
      border-radius: 0px;
      padding: 15px 10px 5px 10px;
      border: none;
      margin-bottom: 5px;

      h5 {
        font-size: 15px;
      }

      background: rgb(236, 236, 236);

      &:hover {
        background: rgb(255, 255, 255);
        box-shadow: 0 0 2px rgb(148, 148, 148);

        h5 {
          color: color(voi);
        }
      }
    }
  }
}

.pointerAdd {
  cursor: pointer;
}

@media screen and (min-width: 1401px) {
  /* CSS rules for screens larger than 1400px */

  .directionRowLarge {
    display: flex;
    flex-direction: row !important;
  }
}

// ADDED for responsive design of login page
.transparent-bg {
  background-color: transparent;
}

@media (min-width: 1440px) {
  .end-txt-loginPage {
    font-size: 6vh;
  }

  .login-form {
    left: 4vw;
  }

  .bg_img_shape {
    left: -2%;
  }
}

@media (min-width: 1920px) {
  .end-txt-loginPage {
    font-size: 6vh;
  }

  .login-form {
    top: 12vh;
    left: 2vw;
  }

  .bg_img_shape {
    left: 0%;
  }

  .end-txt-loginPage {
    font-size: 7vh;
  }
}

@media (min-width: 2245px) {
  .end-txt-loginPage {
    font-size: 6vh;
  }

  .login-form {
    top: 10vh;
    left: 2vw;
  }

  .bg_img_shape {
    left: 0%;
  }

  .end-txt-loginPage {
    font-size: 7vh;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .bg_img_shape {
    left: -10%;
  }

  .login-form {
    top: 20vh;
  }

  .logisticText {
    left: 48vw;
    width: 56%;
  }
}

@media screen and (min-width: 1130px) and (max-width: 1200px) {
  .bg_img_shape {
    left: -18%;
  }

  .login-form {
    top: 20vh;
  }

  .logisticText {
    left: 45vw;
    width: 57%;
    top: 38vh;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1130px) {
  .bg_img_shape {
    left: -30%;
  }

  .logisticText {
    top: 40vh;
    left: 42vw;
    width: 52.5%;
  }

  .login-form {
    top: 20vh;
    left: 1vw;
  }

  .end-txt-loginPage {
    font-size: 4vh;
  }
}

@media screen and (min-width: 980px) and (max-width: 1024px) {
  .bg_img_shape {
    left: -8%;
  }

  .logisticText {
    top: 42vh;
    left: 52vw;
    width: 50%;
  }

  .login-form {
    top: 14vh;
    left: 3vw;
  }

  .end-txt-loginPage {
    font-size: 3.5vh;
  }
}

@media screen and (min-width: 800px) and (max-width: 950px) {
  .bg_img_shape {
    left: -38%;
  }

  .logisticText {
    top: 40vh;
    left: 48vw;
    width: 58%;
  }

  .login-form {
    top: 17vh;
    left: 3vw;
  }

  .end-txt-loginPage {
    font-size: 3.5vh;
  }
}

@media screen and (min-width: 800px) and (max-width: 870px) {
  .logisticText {
    top: 39vh;
    left: 50.5vw;
    width: 58%;
  }

  .end-txt-loginPage {
    font-size: 3.1vh;
  }

  .bg_img_shape {
    left: -42%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .bg_img_shape {
    left: -8%;
  }

  .login-form {
    top: 12vh;
    left: 1vw;
  }

  .login-form form {
    width: 304px;
    height: 250px;
  }

  .logisticText {
    top: 40vh;
    left: 52vw;
  }

  .end-txt-loginPage {
    font-size: 3.5vh;
  }
}

@media screen and (min-width: 900px) and (max-width: 980px) {
  .bg_img_shape {
    left: -12%;
  }

  .login-form {
    top: 10vh;
    left: 2vw;
  }

  .logisticText {
    top: 40vh;
    left: 52vw;
  }

  .end-txt-loginPage {
    font-size: 3.4vh;
  }
}

@media screen and (min-width: 850px) and (max-width: 900px) {
  .bg_img_shape {
    left: -12%;
  }

  .login-form {
    top: 10vh;
    left: 2vw;
  }

  .logisticText {
    top: 40vh;
    left: 52vw;
  }

  .end-txt-loginPage {
    font-size: 3.4vh;
  }
}

@media screen and (min-width: 790px) and (max-width: 850px) {
  .bg_img_shape {
    left: -14%;
  }

  .logisticText {
    top: 38vh;
    left: 52vw;
    width: 58%;
  }

  .login-form {
    top: 13vh;
    left: 2vw;
  }

  .end-txt-loginPage {
    font-size: 3.1vh;
  }
}

@media screen and (min-width: 768px) and (max-width: 790px) {
  .bg_img_shape {
    left: -15%;
  }

  .logisticText {
    top: 38vh;
    left: 54vw;
    width: 58%;
  }

  .end-txt-loginPage {
    font-size: 3vh;
  }

  .login-form {
    left: 1.4vw;
    top: 12vh;
  }
}

@media (max-width: 768px) {
  .bg_img_shape {
    left: -12%;
  }

  .logisticText {
    top: 74vh;
    left: 42vw;
    width: 62%;
  }

  .login-form {
    top: 14vh;
    left: 2vw;
  }

  .end-txt-loginPage {
    font-size: 3.1vh;
  }
}

@media screen and (min-width: 684px) and (max-width: 768px) {
  .logisticText {
    left: 50vw;
  }
}

@media (max-width: 685px) {
  .login-form {
    top: 42%;
    left: 54%;
    transform: translate(-50%, -50%);
  }

  .impexo-white {
    background-color: transparent;
  }

  .login-form form {
    border: none;
  }

  .login-form form:before {
    content: '';
    position: absolute;
    top: 23%;
    left: 0;
    width: 90%;
    height: 72.5%;
    background-color: black;
    opacity: 0.4;
    border-radius: 36px;
  }

  .login_bg_img_div {
    display: none;
  }

  .landingLogin {
    // background-color: $menuBackgroundColor;
    background-color: black;
    // background-image: URL("../images/login-bg.png");
    position: relative;
  }

  .landingLogin::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: URL('../images/login-bg.png');
    // background-color: $menuBackgroundColor;
    opacity: 0.4;
  }

  .logisticText {
    // display: none;
    top: 82%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
  }

  .end-txt-loginPage {
    font-size: 3.1vh;
  }
}

@media (max-width: 435px) {
  .logisticText {
    top: 80%;
    // display: none;
    width: 100%;
  }

  .end-txt-loginPage {
    font-size: 2.8vh;
  }
}

@media (max-width: 352px) {
  .end-txt-loginPage {
    font-size: 2.6vh;
  }
}

//styling for custom margin input with dropdown

.ex-work-texts-margin {
  border-radius: 35px;
  width: 100%;
  height: 29px;
  border: 1px solid $primaryColor;
  font-family: Arial;
  font-size: 12px;
  padding: 13px 0 12px 95px;
  color: $primaryColor;

  &:focus {
    outline: none;
    border: 1.5px solid $primaryColor;
    color: $primaryColor;
  }
}

.ex-work-texts-margin_disabled {
  border-radius: 35px;
  width: 100%;
  height: 29px;

  border: 1px solid $primaryColor;
  font-family: Arial;
  font-size: 12px;
  padding: 13px 0 12px 95px;
  color: $primaryColor;
  background-color: #ccc !important;
}

.ex-work-texts-margin-warn {
  border-radius: 15px;
  width: 100%;
  height: 29px;
  border: 1px solid $deleteColor;
  font-family: Arial;
  font-size: 12px;
  padding: 13px 0 12px 13px;
  color: $primaryColor;

  &:focus {
    outline: none;
    border: 1px solid $deleteColor;
    color: $primaryColor;
  }
}

.custom-margin-input {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-margin-input-pricing {
  width: auto !important;
}

// .custom-margin-input input[type='text'] {
//   padding-right: 40px; /* Make room for the dropdown arrow */
.custom-margin-input input[type='text'] {
  padding-right: 40px;
  /* Make room for the dropdown arrow */
  width: 100%;
}

.custom-margin-input select {
  position: absolute;
  left: 3px;
  top: 2px;
  width: 85px;
  /* Adjust based on your needs */
  border: none;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 12px;
  border-radius: 15px;
  // background-color: $btnBackgroundColor;
  font-family: Arial;
  padding: 5px 5px;
  background-image: URL('../images/arrow_down.png');
  background-position: 95% 58%;
  background-repeat: no-repeat;

  &:focus {
    outline: none;
    padding: 5px 5px;
    background-image: URL('../images/arrow_up.png');
  }
}

.custom-margin-input-period {
  position: relative;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 12px;
  border-radius: 15px;
  // background-color: $btnBackgroundColor;
  font-family: Arial;
  padding: 5px 5px;
  background-image: URL('../images/arrow_down.png');
  background-position: 95% 58%;
  background-repeat: no-repeat;

  &:focus {
    outline: none;
    padding: 5px 5px;
    background-image: URL('../images/arrow_up.png');
  }
}

.marginRed {
  color: red;

  &:focus {
    color: red;
  }
}

.marginBlue {
  color: $primaryColor;

  &:focus {
    color: $primaryColor;
  }
}

.r {
  color: $dangerBtnColor;
}

.b {
  color: $primaryColor;
}

.bl {
  color: #212121;
}

.grandTotal {
  background-color: #9bc1bc;
  color: $whiteColor;
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  justify-content: end;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-top: 1rem;
  font-style: oblique;
}

.margin-seperator {
  position: absolute;
  top: 14px;
  left: 80px;
  width: 16px;
  height: 1px;
  transform: rotate(90deg);
  background-color: #0084d4;
}

.ratingTitlebarSecondary {
  background-color: $secondaryColor;
  padding: 10px 13px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #fff;
}

.product-table-container {
  width: 100%;
  overflow-x: auto;
  /* Adds horizontal scroll if content overflows */
  color: $primaryColor;
}

.product-table-container table {
  width: 100%;
  border-collapse: separate;
}

.product-table-container th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.product-table-container th {
  // background-color: #f0f0f0;
  background-color: #e6f3ff;
}

.product-table-container input[type='text'],
input[type='number'] {
  width: 100%;
  box-sizing: border-box;
}

.bs {
  // box-shadow: 9px 7px 5px rgba(0, 0, 0, 0.3);
}

.mw-200 {
  min-width: 200px;
}

.ml-15 {
  margin-left: -15px;
}

.cp {
  cursor: pointer;
}

.ml-15 {
  margin-left: -15px;
}

.cp {
  cursor: pointer;
}

.wb {
  word-break: break-all;
}

.sales-person-select {
  .css-sfl13s-control {
    padding: 0.2rem;
  }
}

.orangeBall {
  width: 1rem;
  height: 1rem;
  background-color: rgb(252, 191, 77);
  border-radius: 50%;
}

.greenBall {
  width: 1rem;
  height: 1rem;
  background-color: rgba(65, 212, 63, 0.798);
  border-radius: 50%;
}

.bold600 {
  font-weight: 600;
}

.list-containerTransfer {
  margin: 0 auto;
  /* Center the list horizontally */
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  /* Add rounded corners */
}

.list-titleTransfer {
  font-weight: bold;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #ddd;
}

.list-itemsTransfer {
  list-style: none;
  /* Remove default list styling */
  padding: 0;
  margin: 0;
}

.list-itemTransfer {
  margin-bottom: 0.5rem;
}

.parentTransfer>*:nth-child(odd) {
  /* Target every odd child */
  background-color: #f5f5f5;
  /* Light background */
  padding: 0.5rem;
}

.parentTransfer>*:nth-child(even) {
  /* Target every even child */
  background-color: #ddd;
  /* Darker background */
  padding: 0.5rem;
}

.ex-work-texts2 {
  border-radius: 35px;
  width: 180px;
  height: 29px;
  border: 1px solid $primaryColor;
  font-family: Arial;
  font-size: 12px;
  padding: 13px 0 12px 13px;
  color: $primaryColor;

  &:focus {
    outline: none;
    border: 1.5px solid $primaryColor;
    color: $primaryColor;
  }
}

///login revamp styling
.hexaBlue {
  clip-path: polygon(0 0, 72% 0, 100% 50%, 74% 100%, 0 100%, 0% 50%);
  background-color: #04050791;
  position: relative;
  width: 60%;
  height: 100%;
}

.arrowHexa {
  width: 50px;
  height: 50px;
  background-color: #fff;
  clip-path: polygon(50% 0%, 100% 44%, 30% 100%, 62% 45%, 29% 18%);
  position: absolute;
  right: 8px;
  top: 47.5%;
  display: none;
}

.login-formV2 {
  position: absolute;
  top: 40%;
  left: 15%;
  margin-bottom: 15px;
  border: 0.75px solid $whiteColor;
  border-top: transparent;
  border-radius: 41px;
  width: 306px;
  height: 269px;
  padding: 18px;
}

.wrapperLogin {
  display: flex;
  width: 100%;
  height: 100%;
}

.LoginTitleText {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

@media (max-width: 1000px) {
  .wrapperLogin {
    display: flex;
    flex-direction: column;
    background-color: #04050791;
  }

  .hexaBlue {
    background: transparent;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-formV2 {
    left: auto;
  }

  .LoginTitleText {
    margin-bottom: 7rem;
    margin-left: 5px;
  }
}

@media screen and (max-width: 500px) {
  .hexaBlue {
    clip-path: none;
  }

  .imageLogisticBusiness {
    font-size: 2rem !important;
  }

  .LoginTitleText {
    margin-bottom: 2rem !important;

    p {
      margin-left: 10px !important;
    }
  }
}

//frequenucy checkbox design
.activeCb {
  background-color: $primaryColor;
  color: #fff;
  width: 25px;
  height: 22px;
  border-radius: 50%;
}

.inactiveCb {
  background-color: #fafcff;
  color: $primaryColor;
  width: 25px;
  height: 22px;
  border-radius: 50%;
}

.bsr {
  border: 1px solid red;
}

.imageLogisticBusiness {
  display: flex;
  align-items: center;
  font-size: 3rem;
  font-weight: 550;
  line-height: 0.8;
  white-space: nowrap;
  margin-left: 0.5rem;
  color: #fff;
}

.loginForAllText {
  font-size: 2rem;
  font-weight: 400;
  color: #fff;
}

.LoginTitleText {
  p {
    margin-left: 50px;
  }
}

.scrollContainerWrapper {
  max-height: 92vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0rem 0.6rem;
}

/* Remove any previously applied styles that force scrollbar visibility */
.scrollContainerWrapper::-webkit-scrollbar {
  // width: auto; /* Reset to default */
}

.scrollContainerWrapper::-webkit-scrollbar-track,
.scrollContainerWrapper::-webkit-scrollbar-thumb {
  background: transparent;
  /* Make scrollbar elements transparent */
}

/* Firefox */
.scrollContainerWrapper {
  scrollbar-width: auto;
  /* Reset to default */
}

.scrollContainerWrapper::-webkit-scrollbar-thumb {
  background: #8bd6f8;
  /* Color of the scroll thumb */
}


.react-tel-input .flag-dropdown {
  background: transparent !important;
  border: 1px solid $primaryColor !important;
  border-radius: 35px 0 0 35px !important;
  border-right: none !important;
}

.react-tel-input .selected-flag {
  border-radius: 35px 0 0 35px !important;
}

.react-tel-input .selected-flag .arrow {
  background-color: transparent !important;

}

////select styling
.smallH .css-4ljt47-MenuList {
  max-height: 200px !important;
}

.select__value-container.css-g1d714-ValueContainer {
  margin-top: 0px !important;
}

.select__indicator {
  padding: 5px 11px 5px 5px !important;
}

.css-1okebmr-indicatorSeparator,
.css-109onse-indicatorSeparator {
  margin-bottom: 6px !important;
  margin-top: 8px !important;
}

.css-i64u8f-control {
  min-height: 31px !important;
}

//tab styling

.tab-header {
  display: flex;
  // background-color: #685de4;
  background-color: #0088c6;

  // padding: 10px;
}

.tab-header2 {
  display: flex;
  // background-color: #a6a6a6; 
  background-color: $secondaryColor;
}

.tab-header2 p {
  margin: 0;
}

.tab-header2-item {
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;
  // color: #dbdbdc;
  color: #fff;
}

.tab-header2-item.active {
  background-color: #429fd4;
  color: #1c5ed1;
  font-weight: bold;
}






.tab-header p {
  margin: 0;
}

.tab-header-item {
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;
  // color: #dbdbdc;
  color: #fff;
}

.tab-header-item.active {
  background-color: #429fd4;
  color: #1c5ed1;
  font-weight: bold;
}

.m-200 {
  min-width: 200px;
}

.smallH .select--is-disabled {
  background-color: #e9eaf0;
}