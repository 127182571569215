//Primary Button Colors
$primaryColor: #0084d4;
$textColor: #2699fb;
$btnBackgroundColor: #fff;
$countryBackground: #67a7cf;
$secondaryColor:#9bc1bc;

$borderColor: #cce8fa;
//Accordian Colors
$accordionBackground: #d1e8f8;

//Add Submit Delete Cancel Colors
$addColor: #03d162;
$negotiateColor: #0184d4;
$deleteColor: #e80d3f;
$warningColor: #ffa500;
$disabledColor: #333;
$disabledBgColor: #dddddd;
$headerBgColor: #0065a0;
$tableHeaderColor: #1278b3;

//Toolbar

$blackColor: #000;
$whiteColor: #fff;
$hoverColor: #0184d4;
$checkboxBackground: #fcfbfb;
$tableAlternateBgColor: #cee7fa;
$boxShadowColor: rgba(0, 0, 0, 0.20);
$menuBackgroundColor: #0071c4;
$contentBorderColor: #bce0fd;

//GoldenHover Color for rate wizard
$goldenHover: #ffd700;
$errorWarning: rgb(43, 3, 3);

$enquiryRed: #f1abab;
$enquiryGreen: #e5fcee;

//Danger
$dangerBtnColor: #cc0000;
$dangerGradientColor: #c50909;
$dangerBtnHoverColor: #a10707;
