@import "./variables.module";

body {
  font-family: Arial;
}

.menu {
  height: 50px;
  background-color: $menuBackgroundColor;
}

.container1 {
  display: inline-block;
  cursor: pointer;
  margin-left: 1%;
  margin-top: 5px;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: $whiteColor;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.logoimage {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.scrollto {
  width: 50%;
  margin: 0 auto;
}

/* .dot {
      height: 46px;
      width: 46px;
      background-color: $borderColor;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      font-weight: bold;
      color: $menuBackgroundColor;
      padding-top: 10px;
      cursor: pointer;
  } */

.dot:focus {
  outline: none !important;
}

.middle123 {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  list-style: none;
}

.horizontalline {
  width: 150px;
  height: 2px;
  background-color: $borderColor;
  margin-top: 20px;
}

/* .horizontaltwo {
      pointer-events: none;
  } */

/* .customTab1 {
      position: absolute;
      top: 23px;
      left: 508px;
  }
  
  .customTab2 {
      position: absolute;
      top: 23px;
      left: 667px;
  }
  
  .customTab3 {
      position: absolute;
      top: 23px;
      left: 848px;
  } */

.customImportExport {
  text-align: center;
  position: relative;
  top: 11px;
  left: -27px;
  width: 101px;
}

.customService {
  position: relative;
  top: 11px;
  left: -58px;
  width: 158px;
}

.customCommodities {
  position: relative;
  top: 11px;
  left: -25px;
  width: 100px;
}

.quote {
  color: $primaryColor;
  margin-top: 25px;
  font-size: 15px;
  font-family: Arial;
}

.contentsection {
  height: 230px;
  border: 1px solid $contentBorderColor;
  text-align: center;
}

.nav-tabs {
  width: 100%;
}

.switch {
  position: relative;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 15px;
  top: 8px;
  right: 2px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $textColor;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* .slider:after {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $textColor;
      -webkit-transition: 0.4s;
      transition: 0.4s;
  }*/

.slider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 2px;
  bottom: 1.5px;
  background-color: $whiteColor;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $deleteColor;
}

input:focus + .slider {
  box-shadow: 0 0 1px $textColor;
}

input:checked + .slider:before {
  left: -9px;
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 15px;
}

.slider.round:before {
  border-radius: 50%;
}

.export {
  color: $deleteColor;
}

.importexport {
  font-weight: bold;
  margin: 20px;
  font-size: 15px;
  color: $menuBackgroundColor;
  font-family: Arial;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    display: flex;
    align-items: center;
    padding-left: 5px;

    .switch {
      top: 2px;
      margin-right: 3px;
      margin-left: 5px;
    }
  }
}

.shipdate {
  color: $menuBackgroundColor;
  font-size: 14px;
  position: relative;
  top: 4%;
  text-align: center;
}

.exportship {
  color: $deleteColor;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
}

.exportdiv {
  position: relative;
}

.exportsection {
  position: relative;
  background: #fff;
  border: 1px solid $contentBorderColor;
  margin: 20px;
  height: 160px;
  padding-top: 13px;
}

.ship {
  height: 31px;
  width: 33px;
  cursor: pointer;
}

.checkimport {
  color: $contentBorderColor;
  margin-left: 10px;
  display: none !important;
}

/* .shipfont {
    font-weight: bold;
    color: $deleteColor;
  } */

.secondrow {
  font-weight: bold;
  margin: 0 15px;
  font-size: 15px;
  height: 55px;
  font-family: Arial;
}

.firstrow {
  height: 45px;
}

.thirdrow {
  position: relative;
  font-weight: bold;
  margin: 10px;
  margin-top: 25px;
  font-size: 15px;
  color: $menuBackgroundColor;
}

.fourthrow {
  // font-weight: bold;
  font-size: 15px;
  color: $menuBackgroundColor;
}

.datepick {
  // border: none;
  border: 1px solid $primaryColor;
  background-image: url("/images/arrow-down.png");
  background-position: 95% 58%;
  background-repeat: no-repeat;
  // border-bottom: 2px solid $contentBorderColor;
  // color: $deleteColor;
  color: $textColor;
  cursor: pointer;
  // margin-left: 15px;
  // added to make heights of datepicker element and select customer select in requestQuote Create Form
  height: 30px;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none !important;
  opacity: 0;
}

.datepick:focus {
  box-shadow: none !important;
  // border: none !important;
  outline: none !important;
}
.datepick-disabled{
background-color: #d5d5d5 !important;
pointer-events: none !important;
}

// input[type="checkbox"] {
//   // outline: 2px solid $contentBorderColor;
//   // outline-offset: -2px;
// }

#nextbutton:hover {
  font-weight: bold;
}

#previousbutton:focus {
  border-color: $textColor !important;
  outline: none;
}

#previousbutton:hover {
  font-weight: bold;
}

.nextbutton {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  padding: 10px;
}

#nextbutton {
  height: 40px;
  width: 185px;
  // background-color: $headerBgColor;
  background-color: #114f7d;
  border: 1px solid $headerBgColor;
  color: $whiteColor;
  border-radius: 20px;
}

#loginbutton {
  height: 40px;
  width: 185px;
  // background-color: $headerBgColor;
  background-color: #114f7d;
  border: 1px solid $headerBgColor;
  color: $whiteColor;
  border-radius: 20px;
}
#loginbutton:hover {
  font-weight: bold;
}

#loginbutton:focus {
  border-color: $textColor !important;
  outline: none;
}

.formButtons {
  display: flex;
}

#previousbutton {
  height: 40px;
  width: 176px;
  background-color: $whiteColor;
  border: 1px solid $primaryColor;
  color: $textColor;
  margin-right: 35px;
}

#nextbutton:focus {
  border-color: $textColor !important;
  outline: none;
}

.tick {
  display: flex;
  justify-content: right;
  margin-left: auto;
  margin-top: -25px;
  margin-right: 12px;
}

.middle123 li:focus {
  outline: none !important;
}

.mdl-layout__header-row {
  height: 50px !important;
}

.mdl-layout__header {
  min-height: 50px !important;
}

.material-icons {
  font-size: 36px !important;
}

.mdl-layout__drawer-button {
  line-height: 54px;
  outline: none;
}

.mdl-layout__header-row .mdl-navigation__link {
  line-height: 48px !important;
}

.mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
  text-align: left;
  font-weight: 600;
}

.mdl-layout__drawer .mdl-navigation {
  padding-top: 0px !important;
}

.sub-navigation__link {
  background-color: $primaryColor !important;
  padding: 10px 40px !important;
  font-size: 90%;
  margin-right: 5px !important;
  margin-left: 5px !important;
}

@media screen and (max-width: 1024px) {
  .mdl-navigation__link {
    padding: 16px 15px 16px 35px !important;
  }

  .sub-navigation__link {
    padding: 10px 15px 10px 35px !important;
  }
}

.mdl-navigation__link + .submenu_toggler .sub-navigation__link {
  border-top: 5px solid #d1e8f8;
  margin-top: -5px;
}

/* Anish css */

.customShip {
  // position: absolute;
  // top: 65px;
  // left: 98px;
  padding-top: 3px;
  width: 60px;
  height: 60px;
  text-align: center;
  border: 1px solid $contentBorderColor;
  border-radius: 5px;
  cursor: pointer;
}

.customShip:hover {
  box-shadow: 0 0 20px $primaryColor;
}

.orAnd {
  // position: absolute;
  // top: 85px;
  // left: 193px;
  text-align: center;
  padding: 20px;
}

.customAir {
  // position: absolute;
  // top: 65px;
  // left: 248px;
  padding-top: 3px;
  width: 60px;
  height: 60px;
  text-align: center;
  border: 1px solid $contentBorderColor;
  border-radius: 5px;
  cursor: pointer;
}

.customAir:hover,
.customShip:hover {
  box-shadow: 0 0 20px $goldenHover;
}

.airText {
  // position: absolute;
  // top: 34px;
  font-weight: bold;
  color: $primaryColor;
  // padding-left: 23px;
  cursor: pointer;
}

.rateWizHeightFix {
  margin-top: 20px;
  .react-tabs {
    @media (max-width: 992px) {
      min-height: 500px;
    }
  }
}

.cusAir {
  box-shadow: inset 0 0 15px $goldenHover;
}

.cusShip {
  box-shadow: inset 0 0 15px $goldenHover;
}

.shipfont {
  // position: absolute;
  // top: 34px;
  font-weight: bold;
  color: $deleteColor;
  // padding-left: 16px;
  cursor: pointer;
}

/* .contain {
      position: absolute;
      top: 5px;
      left: 5px;
  } */

.customGoods {
  font-size: 12px;
  color: $primaryColor;
}

.horizontaltwo[aria-selected="true"] {
  background-color: $primaryColor !important;
  color: $borderColor !important;
  font-weight: 500;
}

.horizontaltwo {
  height: 46px;
  width: 46px;
  background-color: $borderColor;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  color: $menuBackgroundColor;
  padding-top: 10px;
  cursor: pointer;
}

.righttick {
  margin-left: 40px;
  position: absolute;
}

.previoustick {
  margin-left: 38px;
  position: absolute;
}

#submitbutton {
  height: 40px;
  width: 176px;
  background-color: $whiteColor;
  border: 1px solid $primaryColor;
  color: $textColor;
  margin-left: 35px;
}

#submitbutton:focus {
  border-color: $textColor !important;
  outline: none;
}

#submitbutton:hover {
  font-weight: bold;
}

.submittick {
  margin-left: 34px;
  position: absolute;
}

.marginpanel {
  margin-top: 30px;
}

.css-79elbk {
  position: inherit !important;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  } */

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

input[type="date"] {
  position: relative;
  opacity: 1;
}

input[type="date"]::-webkit-clear-button {
  display: none;
}

.contactDetailModalBody {
  text-align: center;
}

.inputContactDetailModal {
  outline-style: none;
  margin-left: 5px;
  border-radius: 15px;
  width: 50%;
  height: 29px;
  border: 1px solid $primaryColor;
  font-family: Arial;
  font-size: 12px;
  padding: 3% 0 3% 3%;
  color: $primaryColor;
  margin-bottom: 10px;
}

.contactDetailTabs {
  padding: 5px;
}

.addTelephoneBtn {
  float: right;
}

.removeContactDetail {
  margin-top: 4px;
  float: right;
  padding-bottom: 5px;
  color: $deleteColor;
  font-size: 20px;
}

#cardbodyBg {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h6 {
    margin-bottom: 0;
  }
}

// .addBtn {
//   height: 40px;
//   width: 176px;
//   background-color: $whiteColor;
//   color: $addColor;
// }

.plus {
  color: $primaryColor !important;
}
.redBg{
  border-radius: 2px;
  background-color: $deleteColor !important;
  font-size: 1.8rem !important;
  padding: 6px !important;
}
.remove {
  color: $primaryColor !important;
  font-size: 18px !important;
  padding: 0 !important;
}

.validity {
  color: $deleteColor;
  font-size: 10px;
  padding-right: 10px;
  margin-right: 5px;
}

.warn {
  border: 1px solid $deleteColor !important;
}

.error-warning {
  height: 65%;
  width: 100%;
  margin-top: -45px;
  padding: 10px;
  color: $errorWarning;
  font-size: 80%;
  background-color: $accordionBackground;
  word-spacing: 2px;
  line-height: 150%;
}

.pass-error-warning {
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  color: $errorWarning;
  font-size: 80%;
  background-color: $accordionBackground;
  word-spacing: 2px;
  line-height: 150%;
}

.error-warning-cpassword {
  height: 35%;
  width: 100%;
  margin-top: -45px;
  padding: 10px;
  color: $errorWarning;
  font-size: 80%;
  background-color: $accordionBackground;
}

.error-warning-shortname {
  // height: 45%;
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  color: $errorWarning;
  font-size: 80%;
  background-color: $accordionBackground;
  position: absolute;
  z-index: 1;
  color: $primaryColor;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    color: $blackColor;
    border: 11px solid $blackColor;
    border-color: transparent transparent $accordionBackground;
    margin-top: -30px;
    z-index: 99999;
  }
}

.error-warning-username-exist {
  @extend .error-warning-shortname;
  margin-top: 45px;
}

.error-warning-username-exist-kyc {
  @extend .error-warning-shortname;
  margin-top: 16px;
}

.none-border {
  border: none;
}

.add-margin {
  margin-top: 3px;
}

.add-padding {
  padding: 5px;
}

.card-title {
  color: $primaryColor;
}

// .swal-modal {
// 	background-color: $accordionBackground;
// }

// .swal-title {
// 	margin: 0px;
// 	font-size: 14px;
// }

// .swal-button {
// 	padding: 7px 19px;
// 	background-color: $primaryColor;
// 	font-size: 12px;
// 	border: none !important;
// 	outline: none !important;
// 	text-shadow: none !important;
// }
// .swal-button-confirm {
// 	border: none !important;
// 	outline: none !important;
// }
// .swal-button--cancel {
// 	background-color: $primaryColor;
// 	color: $whiteColor;
// }

// .swal-button--cancel:hover {
// 	background-color: #0164a1 !important;
// 	//transition: background-color 0.2s ease;
// }
// .swal-button:hover {
// 	transition: background-color 0.2s ease;
// }
.customer-kyc {
  max-width: 1268px;
  max-height: 720px;
}

.small-text {
  font-size: 12px;
}

.mdl-layout__content {
  background-image: linear-gradient(0, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)),
    url("../images/login-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  background-color: $whiteColor;
}

.table-panel-container {
  background-color: $whiteColor !important;
}

// .search-panel-body {
//   // margin-top: 20px;
// }
// .display-full {
// 	height: 90vh;
// }
.envelope {
  height: 100px;
}

.arrow-down {
  background-image: url("../images/arrow_down.png");
  background-repeat: no-repeat;
  background-position: 95%;
}

.sort-header {
  cursor: pointer;
}

.permissionStaffTable {
  font-size: 12px !important;
  thead {
    th {
      text-align: left !important;
      font-size: 12px !important;
      .permissionCheckbox {
        position: relative;
        top: -3px;
        color: $whiteColor !important;
        user-select: none;
        cursor: pointer;
      }
    }
  }
  tbody {
    td {
      text-align: left !important;
      padding: 0.75rem !important;
      label {
        cursor: pointer;
      }
    }
  }
}
