@import "./variables.module";

.update-password-div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    max-width: 40%;
}
