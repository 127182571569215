/* body{
  margin: 0px 200px;
} */
/* btn commented on 30dec 2019 by ishan */
/* .btn {
	display: inline-block;
	text-align: center;
	cursor: pointer;
	border: none;
	transition: background 0.8s;
	border-radius: 2px;
	padding: 12px 18px;
	font-size: 12px;
	text-transform: uppercase;
	color: white;
	background-color: #2196f3;
	box-shadow: 0 0 4px #999;
	outline: none;
	overflow: "hidden";
	white-space: "nowrap";
	text-overflow: "ellipsis";
} */
/* .btn:hover {
	background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
}

.btn:active {
	background-color: #6eb9f7;
	background-size: 100%;
	transition: background 0s;
} */

.btn_primary {
  background-color: #2196f3;
}
.btn_secondary {
  background-color: #2196f3;
}
.btn_cancel {
  background-color: red;
}

/* The Dialogue Box (background) */
.dialogue_box__background {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  /* padding-top: 100px; Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  /* overflow: auto; Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.dialogue_box__header {
  font-size: 25px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  display: flex;
  flex-direction: row;
}
.dialogue_box__header__text {
  flex: '99%';
  max-width: '98%';
  overflow: 'hidden';
  white-space: 'nowrap';
  text-overflow: 'ellipsis';
}
/* Modal Content */
.dialogue_box {
  background-color: #fefefe;
  /* margin: auto; */
  /* padding: 20px; */
  border: 1px solid #888;
  /* width: 80%; */
  /* overflow: auto; */
}

/* The Close Button */
.dialogue_box_close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  flex: '1%';
}

.dialogue_box_close:hover,
.dialogue_box_close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
/* accordion */

/* Style the buttons that are used to open and close the accordion panel */
/* .accordion__header {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 97.5%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
} */

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
/* .active, .accordion__header:hover {
  background-color: #ccc;
} */

/* Style the accordion panel. Note: hidden by default */
/*.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}*/
/*
.accordion__panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
} */

/* .accordion__header:after { */
/* content: '\02795';  */
/* Unicode character for "plus" sign (+) */
/* font-size: 13px;
  color: #777;
  float: right;
  margin-left: 5px;
} */

/* .accordion .active:after {
  content: "\2796";
} */

/* Style the tab */
/* .tab_header {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
} */

/* Style the buttons that are used to open the tab_header content */
/* .tab_header button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
} */

/* Change background color of buttons on hover */
/* .tab_header button:hover {
  background-color: #ddd;
} */

/* Create an active/current tab_headerlink class */
/* .tab_header button.active {
  background-color: #ccc;
} */

/* Style the tab content */
/* .tab_content {
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
} */

/* .tab_content {
  animation: fadeEffect 1s;  */
/* Fading effect takes 1 second */
/* } */
/* Style the close button */

/* .topright {
  float: right;
  cursor: pointer;
  font-size: 28px;
} */

/* .topright:hover {color: red;} */
/* Go from zero to full opacity */
/* @keyframes fadeEffect {
  from {opacity: 0;}
  to {opacity: 1;}
} */

/* .combo_box__dropdown, .combo_box__field, combo_box__dropdown_option{
  width: 200px;
} */
/* .combo_box__field, .combo_box__dropdown_option{
  height: 30px;
} */
/* .combo_box__dropdown{
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.combo_box__dropdown_option{
  border-bottom: 1px solid #ccc;
} */

/* pagination */
/* .pagination {
  display: inline-block;
} */

/* .pagination li {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
} */

/* .pagination li.active {
  background-color: #4CAF50;
  color: white;
  border-radius: 5px;
} */

/* .pagination li:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
} */
